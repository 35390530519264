import styled, { css } from 'styled-components';

const SplitView = styled.div`
    height: ${props => !props.contentHeight ? '100%' : 'unset'};
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: unset;
    flex-wrap: wrap;

    ${props => props.vertical && css`
        flex-direction: column;
        align-items: unset;
        justify-content: center;
    `}

    ${props => props.justify && css`
        justify-content: ${props.justify};
    `}

    ${props => props.alignItems && css`
        align-items: ${props.alignItems};
    `}

    
    ${props => props.spaced && css`
        >* {
            flex: 1;
        }
    `}

    /* >* {
        height: 100%;
    } */
`;

export default SplitView;