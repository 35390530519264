import { css } from 'styled-components'

export const media = {
    xs: (...args) => css`
        @media (max-width: 480px) {
            ${css(...args)}
        }
    `,
    
    sm: (...args) => css`
        @media (min-width: 481px) {
            ${css(...args)}
        }
    `,
    
    md: (...args) => css`
        @media (min-width: 720px) {
            ${css(...args)}
        }
    `,

    lg: (...args) => css`
        @media (min-width: 960px) {
            ${css(...args)}
        }
    `,

    xl: (...args) => css`
        @media (min-width: 1280px) {
            ${css(...args)}
        }
    `
}