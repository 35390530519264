import React from 'react';
import Filters from '../filters';
import Sidebar from './index';

function FilterBar(props) {
    return (
        <Sidebar current="filters" {...props}>
            <Filters />
        </Sidebar>
    )
}

export default FilterBar;
