import { app as actionTypes } from './actionTypes';

let lastWaiting = null;

export const showToast = props => ({
    type: actionTypes.SHOW_TOAST,
    payload: props
})

export const hideToast = () => ({
    type: actionTypes.HIDE_TOAST
})

export const showHeader = props => ({
    type: actionTypes.SHOW_HEADER,
    payload: props
})

export const hideHeader = () => ({
    type: actionTypes.HIDE_HEADER
})

export const showSidebar = (type, props) => ({
    type: actionTypes.SHOW_SIDEBAR,
    payload: { type, props }
})

export const hideSidebar = () => ({
    type: actionTypes.HIDE_SIDEBAR
})

export const setBreadcrumbs = parts => ({
    type: actionTypes.SET_BREADCRUMBS,
    payload: Array.isArray(parts) ? parts : [parts]
})

export const showModal = (type, title, props) => ({
    type: actionTypes.SHOW_MODAL,
    payload: { type, title, ...props }
})

export const hideModal = result => {
    if (lastWaiting) lastWaiting(result);
    lastWaiting = null;

    return {
        type: actionTypes.HIDE_MODAL
    }
}

export const isLoading = loading => ({
    type: actionTypes.LOADING,
    payload: loading
})

export const isLogonRedirection = redirection =>({
    type: actionTypes.LOGON_REDIRECTION,
    payload: redirection
})

export const waitForDialog = (type, title, props) => dispatch => {
    return new Promise(resolve => {
        dispatch(showModal(type, title, props));
        lastWaiting = resolve;
    })
}