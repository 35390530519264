import { applyMiddleware, compose, createStore } from 'redux';
import persistState from 'redux-sessionstorage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const middleware = [
    thunk
];

const composeEnhancers =
    (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
        compose;

const initializeStore = () => {
    return createStore(
        rootReducer,
        composeEnhancers(
            persistState(['user', 'cart', 'catalogue']),
            applyMiddleware(...middleware)
        )
    );
}

export default initializeStore;