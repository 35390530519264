import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import BackendWarning from '../components/backend-warning';
import CartWarning from '../components/cart-warning';
import { DisplayText } from '../components/display-text';
import ProductDetails from '../components/product-details';
import SessionWarning from '../components/session-warning';
import { hideModal } from '../store/actions/app';
import { media } from '../utils/styles';
import OrderDetails from '../components/dialogs/order-details';

const MODAL_MAP = {
    [ProductDetails.type]: ProductDetails,
    [CartWarning.type]: CartWarning,
    [SessionWarning.type]: SessionWarning,
    [BackendWarning.type]: BackendWarning,
    [OrderDetails.type]: OrderDetails
}

const DialogContainer = styled(({ className, size, fullSize, ...props }) => (
    <MuiDialog className={className} {...props} />
))`
    ${props => props.fullSize && css`
        .MuiPaper-root {
            min-width: 95vw;
            min-height: 85vh;

            
        }
    `};

    ${props => props.size && media.md`
        .MuiPaper-root {
            width: ${props.size.width};
            height: ${props.size.height};
        }
    `};

    ${media.lg`
        .MuiPaper-root {
            min-width: 0;
            min-height: 0;
            max-width: 95vw;
            max-height: 85vh;
        }
    `}
`;

const CloseIcon = styled.div`
    cursor: pointer;
`

const DialogHeader = styled(props => <DialogTitle disableTypography {...props} />)`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
`;

const DialogContents = styled(DialogContent)`
    display: flex;

    padding-top: 0;
    overflow: hidden auto;
`;

const SlideIn = React.forwardRef((props, ref) => (
    <Slide direction='down' ref={ref} {...props} />
))

class Dialog extends Component {
    state = {
        title: null,
        Component: null,
        containerProps: {},
        props: null,
        actions: null,
    }

    componentDidUpdate({ modalProps: oldProps }) {
        const { modalProps: newProps } = this.props;

        if (newProps !== oldProps) {
            // If we're supplied modal props, and a type
            if (newProps && newProps.type) {
                const { type: modalType, title: titleOverride, ...props } = newProps;

                // Try to find the supplied dialog type
                // If not found, just assume we're to show a simple string in the content
                const Component = modalType in MODAL_MAP ? MODAL_MAP[modalType] : modalType;

                // If found, pick up the static configuration
                const { title, actions, ...rest } = Component.dialogProps || {};

                // Update our config
                this.setState({ title: titleOverride || title, Component, containerProps: rest, props, actions })
            } else {
                // Reset the state, after allowing the dialog to exit
                setTimeout(() => {
                    this.setState({ title: null, Component: null, containerProps: {}, props: null, actions: null });
                }, 250);
            }
        }
    }

    render() {
        const { modalProps, handleClose } = this.props;
        const { title, Component, containerProps, props, actions: Actions } = this.state;

        return (
            <DialogContainer
                open={!!modalProps}
                TransitionComponent={SlideIn}
                keepMounted
                onClose={() => handleClose()} {...containerProps}
            >
                <DialogHeader>
                    <DisplayText>{title}</DisplayText>
                    <CloseIcon onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </CloseIcon>
                </DialogHeader>
                <DialogContents>
                    {Component ? (typeof Component === 'string' ? Component : (
                        <Component {...props} onClose={handleClose} />
                    )) : null}
                </DialogContents>
                {Actions ? (
                    <DialogActions>
                        <Actions onClose={handleClose} />
                    </DialogActions>
                ) : null}
            </DialogContainer>
        )
    }
}

const mapStateToProps = ({ app }) => ({
    modalProps: app.modal
})

const mapDispatchToProps = dispatch => ({
    handleClose: result => dispatch(hideModal(result))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);