import { cart as actionTypes } from '../actions/actionTypes';

const initialState = {
    products: {},
    backup: {},
    details: {}
}

export default (state = initialState, { type, payload }) => {
    const { products, details } = state;
    let existing = null;

    switch (type) {
        case actionTypes.SET_CART:
            return {
                ...state,
                products: { ...payload }
            }

        case actionTypes.CLEAR_CART:
            return {
                ...state,
                products: {},
                backup: { ...state.products }
            }

        case actionTypes.ADD_CART:
            existing = products[payload.id];
            if (!existing || payload.force) {
                products[payload.id] = payload.qty;
                details[payload.id] = payload.details;
            }

            return {
                ...state,
                products,
                details
            }

        case actionTypes.REMOVE_CART:
            existing = products[payload.id];
            if (existing) {
                delete products[payload.id];
                delete details[payload.id];
            }

            return {
                ...state,
                products,
                details
            }

        case actionTypes.UPDATE_CART:
            existing = products[payload.id];
            if (existing) products[payload.id] = payload.qty;

            return {
                ...state,
                products
            }

        default:
            return state
    }
}
