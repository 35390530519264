import AuthenticationService from "./authentication-service";
import ExternalService from "./external-services";

class _DataService {
    constructor() {
        this.domain = (process.env.REACT_APP_API_HOST || "") + "api/";
    }

    /**
     * External service ref
     */
    async getUserProfile() {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            return import("./dummy-data/user.js").then(loaded => loaded.DUMMY_USER);
        } else {
            return ExternalService.getUserProfile();
        }
    }

    /**
     * External service ref
     */
    async getUserCards() {
        let cards = [];
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            cards = await import("./dummy-data/user.js").then(
                loaded => loaded.DUMMY_CARDS
            );
        } else {
            cards = await ExternalService.getUserCards();
        }

        return cards.map(card => {
            card.productCode = card.accountIdentifier.productCode;
            card.image = `/cardimages/${card.productCode}.png`;
            card.productCategoryCode = this.mapCardCategory(
                card.accountIdentifier.productCategoryCode
            );
            return card;
        });
    }
    mapCardCategory(category) {
        return category === "CCA" ? category : "OTH";
    }

    /**
     * Custom backend service
     */
    async getUserCart() {
        const {
            cart
        } = await AuthenticationService.apiFetch("user/cart");
        console.log("Cart", cart);
        return cart;
    }

    /**
     * Custom backend service
     */
    async getAllCards() {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            const cards = await import("./dummy-data/user.js").then(
                loaded => loaded.DUMMY_CARDS
            );
            return cards.map(card => {
                card.image = `/images/${card.productCode}.jpg`;
                return card;
            });
        } else {
            const {
                cards
            } = await AuthenticationService.apiFetch(
                "clientProduct/list"
            );

            return cards;
        }
    }

    /**
     * External service ref
     */
    async getCardDetails(card) {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            return import("./dummy-data/user.js").then(
                loaded => loaded.DUMMY_CARD_DETAILS[card.productCode]
            );
        } else {
            return ExternalService.getCardDetails(card);
        }
    }

    /**
     * Custom backend service
     */
    async getProductsForCard(code) {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            return import("./dummy-data/products.js").then(
                loaded => loaded.DUMMY_PRODUCTS[code]
            );
        } else {
            const {
                products
            } = await AuthenticationService.apiFetch(
                `clientProduct/${code}/catalogue`
            );

            return products.map(details => {
                // Parse out the images
                details.images = details.images.split(",");
                details.image = details.images[0];
                // Parse out the cost
                details.cost = parseInt(details.cost);

                return details;
            });
        }
    }

    /**
     * Custom backend service
     */
    async getProductDetails(code, productId) {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            return import("./dummy-data/products.js").then(
                loaded => loaded.DUMMY_PRODUCT_DETAILS[productId]
            );
        } else {
            const {
                details
            } = await AuthenticationService.apiFetch(
                `clientProduct/${code}/detail/${productId}`
            );
            // Parse out the images
            details.images = details.images.split(",");
            details.image = details.images[0];
            // Parse out the cost
            details.cost = parseInt(details.cost);
            // Add terms
            //details.terms = await import('../utils/terms.js').then(loaded => loaded.default);

            return details;
        }
    }

    /**
     * Custom backend service
     */
    async getIndividualProductRemarks(transactionRef) {
       
            const {
                details
            } = await AuthenticationService.apiFetch(
                `order/remarks/${transactionRef}`
            );
            
			console.log('details: ',details.map((det)=>{
				return det.remarks;
            }));
			
            return details.map((det)=>{
				return det.remarks;
            });
       
    }

    async updateWishlist(productId, remove = false) {
        const failed = await AuthenticationService.apiPost(
            `user/wishlist/${remove ? "remove" : "add"}`, {
                body: {
                    productId
                }
            }
        );
        console.log("Wishlist", failed);
    }

    async getUserWishlist() {
        const {
            items
        } = await AuthenticationService.apiFetch(`user/wishlist`);

        return items ? items.map(item => item.productId) : [];
    }

    async getUserHistory() {
        const {
            orders
        } = await AuthenticationService.apiFetch(`order/history`);

        return orders;
    }

    /**
     * External service ref
     */
    async checkoutForCard(card, products) {
        if (process.env.REACT_APP_DUMMY_MODE !== "false") {
            return true;
        } else {
            // 1. Create an order on the backend
            const {
                transactionRef,
                id
            } = await AuthenticationService.apiPost(
                `order/create/${card.productCode}`, {
                    body: {
                        cart: products.map(product => ({
                            productId: product.id,
                            quantity: product.quantity
                        }))
                    }
                }
            );

            console.log("transactionRef: " + (await transactionRef));

            // 2. Next redeem the points
            if (await transactionRef) {
                console.log("transactionRef: " + (await transactionRef));
                const redeemed = await ExternalService.redeemProducts(
                    card,
                    products,
                    await id
                );
                console.log("redeemed: " + redeemed);

                // 3. Once redeemed, confirm the order
                if (redeemed) {
                    const {
                        success
                    } = await AuthenticationService.apiPost(
                        `order/confirm/${transactionRef}`
                    );

                    if(success) {
                        const {
                            result
                        } = await AuthenticationService.apiPost(
                            `order/confirmProducts/${transactionRef}`
                        )
                        return result;
                    } else {
                        return success;
                    }
                }
            }
            return false;
        }
    }

    /**
     * External service ref
     */
    logout() {
        if (process.env.REACT_APP_DUMMY_MODE === "false") {
            ExternalService.logout();
        }
    }

    /**
     * External service ref
     */
    login() {
        if (process.env.REACT_APP_DUMMY_MODE === "false") {
            ExternalService.login();
        }
    }

    /**
     * Custom backend service
     */
    async startSession(email) {
        return AuthenticationService.startSession(email);
    }

    /**
     * Custom backend service
     */
    stopSession() {
        return AuthenticationService.logout();
    }

    _dataRequest(path, options) {
        return AuthenticationService.fetch(`${this.domain}${path}`, options);
    }
}

const DataService = new _DataService();
export default DataService;