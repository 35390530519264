import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    background-color: black;
    
    /* min-height: 5rem; */
`;

function Footer() {
    return (
        <FooterContainer>

        </FooterContainer>
    )
}

export default Footer
