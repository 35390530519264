import { Checkbox, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ControlledLabelLeft from './control-label-left';

export default class MultiSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        options: PropTypes.array,
        label: PropTypes.string
    }

    handleChange({ target }) {
        const { value, onChange } = this.props;
        const { name, checked } = target;
        const groupValue = value || [];

        const hasTarget = groupValue.indexOf(name);
        if (!checked && hasTarget >= 0) {
            groupValue.splice(hasTarget, 1);
        } else if (checked && hasTarget < 0) {
            groupValue.push(name);
        }

        onChange(groupValue);
    }

    render() {
        const { label, options, value } = this.props;

        return options ? (
            <FormGroup aria-label={label}>
                {
                    options.map((option, index) => (
                        <ControlledLabelLeft
                            key={`${label}-checkbox-${index}`}
                            name={option}
                            label={option}
                            checked={!!value && value.indexOf(option) >= 0}
                            onChange={evt => this.handleChange(evt)}
                            control={<Checkbox id={option} />}
                        />
                    ))
                }
            </FormGroup>
        ) : null
    }
}
