import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { DisplayText } from '../display-text';

export const WarningContent = styled.div`
    display: flex;
    flex-direction: column;    
`;

export const WarningButton = styled(({ text, color, ...props }) => (
    <Button {...props}>
        <DisplayText color={color}>
            {text}
        </DisplayText>
    </Button>
))``;