import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import FilterBar from '../components/sidebar/filter-bar'
import UserBar from '../components/sidebar/user-bar'

const Container = styled.div`
    height: auto;
    position: relative;
`

function WrappedSidebar({ type, open }) {
    switch (type) {
        case 'filters':
            return <FilterBar isOpen={open} />;
        case 'profile':
            return <UserBar isOpen={open} />;
        default:
            return null;
    }
}

function Sidebars({ type }) {
    const [current, setCurrent] = React.useState('');

    React.useEffect(() => {
        if (current !== type) {
            setTimeout(() => setCurrent(type), 300);
        }
    }, [current, type]);

    return (
        <Container>
            <WrappedSidebar type={current} open={(type === current)} />
        </Container>
    )
}

const mapStateToProps = ({ app }) => ({
    type: app.sidebar.type
})


export default connect(mapStateToProps)(Sidebars)
