import React from 'react';
import { DisplayText } from '../display-text';
import { connect } from 'react-redux';

const ProfileError = ({ profile }) => (
    // <DisplayText size="small" color="light">Could not fetch details ({profile ? profile.responseInfo.requestCorrelationId : ''})</DisplayText>
    <DisplayText size="small" color="light"> Not Updated</DisplayText>
)

const mapStateToProps = ({ user }) => ({
    profile: user.profile
})

export default connect(mapStateToProps)(ProfileError);