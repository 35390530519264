import { StylesProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import BackendWarning from '../components/backend-warning';
import Breadcrumbs from '../components/breadcrumbs';
import LoadingScreen from '../components/loading';
import LogonRedirectionScreen from '../components/logonredirection';
import SessionWarning from '../components/session-warning';
import ToastNotification from '../components/toast-notification';
import DataService from '../services/data-service';
import { isLoading, waitForDialog } from '../store/actions/app';
import { login, logout } from '../store/actions/user';
import Dialog from './dialog';
import Footer from './footer';
import Header from './header';
import PageContent from './page-content';
import Sidebars from './sidebars';

const LayoutRoot = styled.div`
    text-align: center;

    /* Setup the root display as a vertical stack */
    display: flex;
    flex-direction: column;

    /* Each direct child gets full width */
    >* {
        width: 100%;
    }
`;

const LayoutMain = styled.div`
    /* Fit everything */
    height: 100%;
    /* Hide any overflow */
    overflow: hidden;
    min-height: 100vh;

    /* The main content area is a horizontal stack */
    display: flex;
    flex-direction: row;

    /* Each direct child gets full width */
    >* {
        /* min-height: inherit; */
    }
`;

const LayoutContent = styled.div`
    /* Occupy any available space */
    flex: 1;

    /* The page content area is a vertical stack */
    display: flex;
    flex-direction: column;

    /* Each child gets the full width */
    >* {
        width: 100%;
    }
`;

export default class App extends Component {
    async componentDidMount() {
        // Check if we have a user already
        const { user } = this.props.store.getState();

        try {
            // Start the loader
            this.props.store.dispatch(isLoading(true));

            // On first mount, try to load the user profile
            const profile = await DataService.getUserProfile();

            // If successful, set the profile
            if (profile) {
                if (profile.contactDetails && profile.contactDetails.customerEmail) {
                    await this.props.store.dispatch(login(profile));
                } else {
                    this.props.store.dispatch(isLoading(false));
                    // We need atleast the email, and mobile to confirm the user
                    // Force the user to check back in later
                    await this.props.store.dispatch(waitForDialog(BackendWarning.type));
                    // Back to logout
                    DataService.logout();
                }
            }
        } catch (e) {
            this.props.store.dispatch(isLoading(false));

            // GO to the home page, if on the checkout pages
            if (window.location.pathname.startsWith('/checkout'))
                window.location = '/';

            // Check for session user
            if (user && user.profile) {
                // First unset the existing profile
                this.props.store.dispatch(logout());
                // Inform the user about clearing the profile
                await this.props.store.dispatch(waitForDialog(SessionWarning.type));
                // Then redirect to the external location
                DataService.logout();
            }

            // Log the error
            console.error('Error while getting user profile', e);
        } finally {
            // Stop the loader
            this.props.store.dispatch(isLoading(false));
        }
    }

    render() {
        return (
            // First, setup the various providers
            <Provider store={this.props.store}>
                <StylesProvider injectFirst>
                    <Router>
                        {/* Root of our app layout */}
                        <LayoutRoot>
                            <Header />
                            {/* The middle section, containing the main content */}
                            <LayoutMain>
                                <Sidebars />
                                {/* The page specific content */}
                                <LayoutContent>
                                    <Breadcrumbs />
                                    <PageContent />
                                </LayoutContent>
                            </LayoutMain>
                            <Footer />
                        </LayoutRoot>
                        {/* Absolutely positioned elements */}
                        <Dialog />
                        <ToastNotification />
                        <LoadingScreen />
                        <LogonRedirectionScreen />
                    </Router>
                </StylesProvider>
            </Provider>
        )
    }
}
