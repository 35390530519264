import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { media } from '../../utils/styles';
import { hideSidebar } from '../../store/actions/app';

const Container = styled.div`
    ${({ isOpen, props }) => props.fullWidth ? css`
        flex: ${isOpen ? 1 : 0};
        transition: flex 300ms ease-in-out;

        ${isOpen && css`
            ~div {
                display: none;
            }
        `}
    ` : css`
        width: 18rem;
        margin-left: ${isOpen ? '0' : '-18rem'};
        transition: margin-left 300ms ease-in-out;
    `}

    z-index: 100;

    height: 100%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 1px 1px 2px #d7d8d6;

    >div {
        padding: 0rem 1rem;
        padding-bottom: 2rem;
    }
`;

const MobileContainer = styled.div`
    position: fixed;
    z-index: 99;
    // width: 26rem;
    height: 100%;
    // background-color: rgba(0,0,0,0.4);

    >div {
        position: absolute;
        top: 0;
        left: 0;

        >div {
            padding-bottom: 15rem;
        }
    }
`;

const BackDrop = styled.div`
    position: fixed;
    z-index: 99;
    
    ${media.xs`
        min-width: 425px;
    `}

    ${media.md`
        min-width: 768px;
    `}
    
    height: 100%;
    background-color: rgba(0,0,0,0.4);

    >div {
        position: absolute;
        top: 0;
        left: 0;

        >div {
            padding-bottom: 15rem;
        }
    }
`;

function withMobile(Component, close) {
    return (
        <MobileContainer>
            {Component}
            <BackDrop onClick={()=>close()}/>
        </MobileContainer>
    )
}


function Sidebar({ className, isOpen, type, current, close, ...rest }) {
    const isTabletOrMobile = useMediaQuery('(max-width: 1024px)');
    
    const _Sidebar = <Container className={className} isOpen={isOpen} {...rest}  />;
    return isTabletOrMobile ? withMobile(_Sidebar, close) : _Sidebar;
}

const mapStateToProps = ({ app: { sidebar } }) => ({
    ...sidebar
})

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(hideSidebar())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
