import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import styled from 'styled-components';
import { DisplayText } from './display-text';

const WarningContent = styled.div`
    display: flex;
    flex-direction: column;    
`;

const WarningButton = styled(({ text, color, ...props }) => (
    <Button {...props}>
        <DisplayText color={color}>
            {text}
        </DisplayText>
    </Button>
))``;

export default class CartWarning extends Component {
    static propTypes = {

    }

    static type = 'CART_WARNING'
    static dialogProps = {
        title: (
            <DisplayText color="primary">Warning</DisplayText>
        ),
        actions: ({ onClose }) => (
            <>
                <WarningButton text='Proceed' color='primary' onClick={() => onClose(true)} />
                <WarningButton text='Cancel' color='light' onClick={() => onClose(false)} />
            </>
        )
    }

    render() {
        const { count } = this.props;
        return (
            <WarningContent>
                <DisplayText>
                    Please note that the items added to the cart will be lost if you select the option to proceed.
                    {/* Switching the card will <b>clear</b> the {count} items which are in your cart. */}
                </DisplayText>
                <DisplayText>
                    Are you sure you want to continue?
                </DisplayText>
            </WarningContent>
        )
    }
}
