import { cart as actionTypes } from './actionTypes';

export const setCart = productList => ({
    type: actionTypes.SET_CART,
    payload: productList
})

export const clearCart = () => async dispatch => {
    
    dispatch({
        type: actionTypes.CLEAR_CART,
        payload: {}
    })
}

export const addOrUpdateCart = (productId, qty) => async (dispatch, getState) => {
    const product = getState().catalogue.products[productId];

    dispatch({
        type: actionTypes.ADD_CART,
        payload: { id: productId, qty, force: true, details: { ...product } }
    })
}

export const addToCart = (productId, qty) => async (dispatch, getState) => {
    const product = getState().catalogue.products[productId];

    dispatch({
        type: actionTypes.ADD_CART,
        payload: { id: productId, qty, details: { ...product } }
    })
}

export const updateCart = (productId, qty) => async dispatch => {

    dispatch({
        type: actionTypes.UPDATE_CART,
        payload: { id: productId, qty }
    })
}

export const removeFromCart = productId => async dispatch => {

    dispatch({
        type: actionTypes.REMOVE_CART,
        payload: { id: productId }
    })
}