import styled, { css } from 'styled-components';

export const SimpleLink = styled.button`
    text-decoration: none;
    background: none;
    outline: none;
    border: none;
    color: black;

    ${props => props.underline && css`
        :hover {
            text-decoration: underline;
        }
    `}

    position: relative;

    cursor: pointer;
`;