import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearAllFilters, clearFilter, setFilter } from '../../store/actions/catalogue';
import { DisplayText } from '../display-text';
import { SimpleLink } from '../simple-link';
import { SIDEBAR_FILTERS } from './config';

const FiltersContainer = styled.div`
    
`;

const FiltersHeader = styled.div`
    height: 2.5rem;
    border-radius: 5px;
    
    cursor: default;
    background-color: #f8f9fa;
    span {
        color: black;
    }
    
    padding: 0 0.5rem;

    display: flex;
    justify-content: space-between;
    align-items: left;

    >:first-child {
        width: 30%;
    }
`;

const FilterIcon = styled.span`
    flex: 1;
`;

const FilterSections = styled.div`
    max-height: 100%;
`;

const FilterContainer = styled.div`
    margin-top: 1rem;
    height: 100%;
`;

const FilterTitle = styled.div`
    height: 100%;
    text-align: left;
    color: #db0011;

    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        width: 100%;
    }

    svg {
        cursor: pointer;
    }
`;

const FilterClear = styled.div`
    height: 100%;
    text-align: center;
    
    flex: 1;
    >* {
        height: 100%;
    }
`;

const FilterSeparator = styled.div`
    height: 1px;
    width: 100%;
    
    margin-top: 0.5rem;

    background-color: lightgray;
`;

const FilterContent = styled.div`
    height: 100%;
    padding: 0 1rem;
    max-height: 0;
    overflow: hidden;
    
    transition: max-height 100ms ease-in-out;
    &.open {
        max-height: 15rem;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 14px 20px;
    }
`;

const FilterSection = ({ title, className, children, filterKey, filterCleared }) => {
    const [open, setOpen] = React.useState(true);

    return (
        <FilterContainer className={className}>
            <FilterTitle >
                <DisplayText color="inherit" variant="regular">
                    {title}
                </DisplayText>
                <FilterIcon onClick={() => setOpen(!open)}>
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                </FilterIcon>
                <FilterClear>
                    <SimpleLink onClick={() => filterCleared(filterKey)} style={{'margin-left': '-70px'}}>
                        <DisplayText size="small">Clear</DisplayText>
                    </SimpleLink>
                </FilterClear>
            </FilterTitle>
            <FilterSeparator />
            <FilterContent className={open ? 'open' : ''}>
                {children}
            </FilterContent>
        </FilterContainer>
    )
}

class Filters extends Component {
    state = {
        filtersVisible: false
    }

    render() {
        const { products, filters, filterCleared, filterChanged } = this.props;
        const { filtersVisible } = this.state;
        return (
            <FiltersContainer>
                <FiltersHeader>
                    <FilterTitle onClick={() => this.setState({ filtersVisible: !filtersVisible })}>
                        <DisplayText variant="strong">Filters</DisplayText>
                        <Hidden mdUp={true}>
                            <FilterIcon>
                                <FontAwesomeIcon color="white" icon={filtersVisible ? faAngleUp : faAngleDown} />
                            </FilterIcon>
                        </Hidden>
                    </FilterTitle>
                    <FilterClear>
                        <SimpleLink onClick={() => filterCleared()}>
                            <DisplayText size="small">Clear All</DisplayText>
                        </SimpleLink>
                    </FilterClear>
                </FiltersHeader>
                <FilterSections isActive={filtersVisible}>
                    {
                        SIDEBAR_FILTERS.map((filter, index) => (
                            <FilterSection key={`filter-section-${index}`} filterKey={filter.key} title={filter.display} filterCleared={filterCleared}>
                                <filter.type
                                    value={filters[filter.key]}
                                    onChange={value => filterChanged(filter.key, value)} {...(filter.getProps(products) || {})}
                                />
                            </FilterSection>
                        ))
                    }
                </FilterSections>
            </FiltersContainer>
        )
    }
}

const mapStateToProps = ({ catalogue }) => ({
    filters: catalogue.filters,
    products: Object.values(catalogue.products)
});

const mapDispatchToProps = dispatch => ({
    filterChanged: (key, value) => dispatch(setFilter(key, value)),
    filterCleared: key => dispatch(key ? clearFilter(key) : clearAllFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);