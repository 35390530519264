import React, { Component } from 'react';
import ZoomImage from 'react-image-magnify';
import Carousel from 'react-slick';
import { uid } from 'react-uid';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, { css } from 'styled-components';

const Banner = styled(Carousel)`
    width: 100%;
    height: 100%;

    >div {
        width: inherit;
        height: inherit;
    }

    .slick-arrow {
        color: #d7d8d6;
        background: #767676;
    }
`;

const BannerItem = styled.div`
    img {
        width: 100%;
        object-fit: contain;
    }

    ${props => props.image && css`
        background-image: url(${props.image});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    `}
`;

const RegularImage = styled.img`

`;

export default class BannerCarousel extends Component {
    getDisplayImage(config, zoom) {
        const imageSrc = typeof config === 'string' ? config : config.image;
        const hqSrc = typeof config === 'string' ? config : (config.hq || config.image);
        const imageProps = zoom ? {
            smallImage: {
                src: imageSrc,
                isFluidWidth: true
            },
            largeImage: {
                src: hqSrc,
                width: 1280,
                height: 1280
            },
            isHintEnabled: true,
            shouldHideHintAfterFirstActivation: true,
            enlargedImagePosition: 'over'
        } : { src: imageSrc };
        const ImageComponent = zoom ? ZoomImage : RegularImage;

        return <ImageComponent {...imageProps} />
    }

    render() {
        const { className, banners, showZoom } = this.props;
        return (
            <Banner dots={true} arrows={false} showThumbs={false} slidesToScroll={1} slidesToShow={1} showStatus={false} className={className}>
                {banners.map((banner, index) => (
                    <BannerItem key={uid(banner, index)}>
                        {this.getDisplayImage(banner, showZoom)}
                    </BannerItem>
                ))}
            </Banner>
        )
    }
}
