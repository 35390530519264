import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { DisplayText, Header } from '../display-text';
import ProfileError from './profile-error';
import {media} from '../../utils/styles';

const ShippingContainer = styled.div`

`;

const ShippingDetails = styled.div`
    display: flex;

    ${media.md`
        padding: 2rem;
    `}

    ${media.xs`
        padding: 2rem 0;
    `}
`;

const DetailLine = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;

    >span {
        height: 4rem;
    }

    ${props => props.variant === 'keys' && css`
        >span {
            color: dimgray;
            font-weight: 700;
            font-size: 0.875rem;
        }
    `};
    
    ${props => props.variant === 'values' && css`
        >span {
            
        }
    `};
`;

class OrderShipping extends Component {
    static propTypes = {

    }

    render() {
        const { profile } = this.props;
        const { personalInformation, contactDetails } = profile;
        return (
            <ShippingContainer>
                <Header>Shipping Address</Header>
                <DisplayText color="primary" size="small">
                    Note: Items will be delivered to your credit card statement mailing address updated in the Bank records. Electronic gift vouchers will be delivered only via email/SMS on the registered email ID and mobile number below 
                </DisplayText>
                <ShippingDetails>
                    <DetailLine variant="keys">
                        <DisplayText>First Name</DisplayText>
                        <DisplayText>Last Name</DisplayText>
                        <DisplayText>Mobile Number</DisplayText>
                        <DisplayText>Email</DisplayText>
                    </DetailLine>
                    <DetailLine variant="values">
                        {personalInformation && personalInformation.customerName ? <DisplayText>{personalInformation.customerName.firstName}</DisplayText> : <ProfileError />}
                        {personalInformation && personalInformation.customerName ? <DisplayText>{personalInformation.customerName.lastName}</DisplayText> : <ProfileError />}
                        {contactDetails && contactDetails.mobilePhoneNumber ? <DisplayText>{contactDetails.mobilePhoneNumber.fullNumber}</DisplayText> : <ProfileError />}
                        {contactDetails && contactDetails.customerEmail ? <DisplayText>{contactDetails.customerEmail.emailAddress}</DisplayText> : <ProfileError />}
                    </DetailLine>
                </ShippingDetails>
                <DisplayText color="secondary" size="small">
                    <b>Disclaimer</b>: If your statement address, email ID or mobile number need to be updated, please reach out to your nearest HSBC India Branch or call HSBC PhoneBanking prior to placing the request to avoid an incorrect delivery.
                </DisplayText>
            </ShippingContainer>
        )
    }
}

const mapStateToProps = ({ user }) => ({
    profile: user.profile
})

export default connect(mapStateToProps)(OrderShipping);