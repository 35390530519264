/**
 * App actions
 */
export const app = {
    SHOW_TOAST: 'SHOW_TOAST',
    HIDE_TOAST: 'HIDE_TOAST',
    SHOW_HEADER: 'SHOW_HEADER',
    HIDE_HEADER: 'HIDE_HEADER',
    SHOW_SIDEBAR: 'SHOW_SIDEBAR',
    HIDE_SIDEBAR: 'HIDE_SIDEBAR',
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    WAIT_FOR_DIALOG: 'WAIT_DIALOG',
    LOADING: 'LOADING',
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    LOGON_REDIRECTION : 'LOGON_REDIRECTION'
};

export const user = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_WISHES: 'SET_WISHLIST',
    ADD_WISH: 'ADD_WISHLIST',
    REMOVE_WISH: 'REMOVE_WISHLIST',
    SET_CARDS: 'SET_CARDS',
    SELECT_CARD: 'SELECT_CARD'
}

export const cart = {
    SET_CART: 'SET_CART',
    ADD_CART: 'ADD_CART',
    REMOVE_CART: 'REMOVE_CART',
    UPDATE_CART: 'UPDATE_CART',
    CLEAR_CART: 'CLEAR_CART'
}

export const catalogue = {
    SET_ACTIVE_CARD: 'SET_ACTIVE_CARD',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SELECT_PRODUCT: 'SELECT_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    RESET_PRODUCT: 'RESET_PRODUCT',
    SET_FILTER: 'SET_FILTER',
    CLEAR_FILTER: 'CLEAR_FILTER',
    CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
    REFRESH_LIST: 'REFRESH_LIST',
    SHOW_FILTERS: 'SHOW_FILTERS',
    HIDE_FILTERS: 'HIDE_FILTERS'
}