import Fuse from 'fuse.js';
import MultiSelect from './multi-select';
import RangeSlider from './range';
import SingleSelect from './single-select';

export const SIDEBAR_FILTERS = [
    {
        key: 'points',
        display: 'Point Range',
        type: RangeSlider,
        getProps: products => {
            const props = products.reduce((acc, curr) => {
                acc.min = (curr.cost < acc.min) ? curr.cost : acc.min;
                acc.max = (curr.cost > acc.max) ? curr.cost : acc.max;
                return acc;
            }, {
                min: Number.MAX_VALUE,
                max: Number.MIN_VALUE
            });
            props.min = props.min === Number.MAX_VALUE ? 0 : props.min;
            props.max = props.max === Number.MIN_VALUE ? 100 : props.max;

            return props;
        },
        applyFilter: (products, filter) => {
            if (filter) {
                return products.filter(p => p.cost >= filter[0] && p.cost <= filter[1]);
            }
        }
    },
    {
        key: 'category',
        display: 'Category',
        type: SingleSelect,
        getProps: products => {
            return products.reduce((acc, curr) => {
                const existing = acc.options.indexOf(curr.type);
                if (existing < 0) acc.options.push(curr.type);

                return acc;
            }, {
                options: []
            });
        },
        applyFilter: (products, filter) => {
            if (filter) {
                return products.filter(p => p.type === filter);
            }
        }
    },
    {
        key: 'sub-category',
        display: 'Sub Category',
        type: MultiSelect,
        getProps: products => {
            return products.reduce((acc, curr) => {
                const existing = acc.options.indexOf(curr.category);
                if (existing < 0) acc.options.push(curr.category);

                return acc;
            }, {
                options: []
            });
        },
        applyFilter: (products, filter) => {
            if (filter && filter.length) {
                return products.filter(p => filter.indexOf(p.category) >= 0);
            }
        }
    },
    {
        key: 'brands',
        display: 'Brands',
        type: MultiSelect,
        getProps: products => {
            return products.reduce((acc, curr) => {
                const existing = acc.options.indexOf(curr.brandName);
                if (existing < 0) acc.options.push(curr.brandName);

                return acc;
            }, {
                options: []
            });
        },
        applyFilter: (products, filter) => {
            if (filter && filter.length) {
                return products.filter(p => filter.indexOf(p.brandName) >= 0);
            }
        }
    }
];

export const SEARCH_FILTER = {
    key: 'search',
    display: 'Search...',
    applyFilter: (products, filter) => {
        const { index, term } = filter;
        if (index && term) {
            const fuse = new Fuse([], index.options);
            fuse.setCollection(products);
            return fuse.search(term);
        }
    }
}

export const applyFilters = (products, filters) => {
    return new Promise(resolve => {
        // Filters are key value pairs
        // Apply each filter in sequence with a timeout
        const allFilters = Object.entries(filters);

        let filterIndex = 0;
        const doFilter = () => {
            const [key, value] = allFilters[filterIndex];
            const selectedFilter = SIDEBAR_FILTERS.find(item => item.key === key);
            if (selectedFilter) products = selectedFilter.applyFilter(products, value) || products;
            else if (key === SEARCH_FILTER.key) products = SEARCH_FILTER.applyFilter(products, value) || products;

            // Next filter
            filterIndex++;

            // Iterate
            if (filterIndex < allFilters.length) setTimeout(doFilter, 1);
            // Final result
            else resolve(products);
        }

        // Add a minimum 150ms delay to show the loader
        if (allFilters.length) setTimeout(doFilter, 1);
        else resolve(products);
    })
}