import { catalogue as actionTypes } from './actionTypes';
import DataService from '../../services/data-service';

export const selectCardForCatalogue = cardCode => ({
    type: actionTypes.SET_ACTIVE_CARD,
    payload: cardCode
})

export const setProducts = (cardCode, productList) => dispatch => {
    // Set the catalogue card
    dispatch({
        type: actionTypes.SET_ACTIVE_CARD,
        payload: cardCode
    });

    if (!productList) return;

    // Set the products
    dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: productList.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
        }, {})
    })
}

export const selectProduct = product => async dispatch => {
    dispatch({
        type: actionTypes.SELECT_PRODUCT,
        payload: product
    })

    await dispatch(getProductDetails(product.id));
}

export const getProductDetails = productId => async (dispatch, getState) => {
    const productDetails = await DataService.getProductDetails(getState().catalogue.cardCode, productId);

    dispatch({
        type: actionTypes.UPDATE_PRODUCT,
        payload: productDetails
    })
}


export const setFilter = (key, value) => ({
    type: actionTypes.SET_FILTER,
    payload: { key, value }
})

export const clearFilter = key => ({
    type: actionTypes.CLEAR_FILTER,
    payload: key
})

export const toggleCatalogueFilters = show => ({
    type: show ? actionTypes.SHOW_FILTERS : actionTypes.HIDE_FILTERS
})

export const clearAllFilters = () => ({
    type: actionTypes.CLEAR_ALL_FILTERS
})

export const refreshCatalogue = refresh => ({
    type: actionTypes.REFRESH_LIST,
    payload: refresh
})