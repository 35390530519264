import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uid } from 'react-uid'
import styled from 'styled-components'
import { DisplayText } from '../components/display-text'
import { setBreadcrumbs, showSidebar } from '../store/actions/app'

const Question = styled.div`

`;

const Answer = styled.div`

`;

const Section = styled.div`
    text-align: left;
    padding-top: 1rem;
`;

const Container = styled.div`
    padding: 1rem;
`;

class FAQ extends Component {
    state = {
        qna: []
    }

    async componentDidMount() {
        const { showBreadcrumbs } = this.props;
        this.props.toggleSidebar('');

        showBreadcrumbs([{ name: 'Home', link: '/' }, { name: `Frequently Asked Questions` }]);

        const { qna } = await import('../utils/faqs').then(module => module.default);
        this.setState({ qna });
    }

    render() {
        const { qna } = this.state;
        return (
            <Container>
                {qna.length ? qna.map((item, index) => (
                    <Section key={uid(item, index)}>
                        <Question>
                            <DisplayText color="#333" variant="strong">
                                {item.q}
                            </DisplayText>
                        </Question>
                        <Answer>
                            <DisplayText size="small" dangerouslySetInnerHTML={{ __html: item.a }}></DisplayText>
                        </Answer>
                    </Section>
                )) : null}
            </Container>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    showBreadcrumbs: (paths) => dispatch(setBreadcrumbs(paths)),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props))
})


export default connect(null, mapDispatchToProps)(FAQ)
