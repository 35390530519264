import { Button, Tab, Tabs } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import DataService from '../services/data-service';
import { showToast, isLogonRedirection } from '../store/actions/app';
import { addOrUpdateCart } from '../store/actions/cart';
import { media } from '../utils/styles';
import BannerCarousel from './catalogue/banner-carousel';
import { DisplayText } from './display-text';
import SelectQuantity from './select-qty';

const Details = styled.div`
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    ${media.md`
        flex-direction: row;
    `}
`;

const DetailTabs = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: auto;
    // scrollbar-width: 5px; /* For Firefox */
    // -ms-overflow-style: none; /* For Internet Explorer and Edge */
    // ::-webkit-scrollbar {
    //     width: 0px; /* For Chrome, Safari, and Opera */
    // }
`;

const ProductImages = styled.div`
    height: 18rem;
    width: 18rem;
    max-height: 18rem;
    
    ${media.xs`
        height: 12rem;
        width: 12rem;
        max-height: 12rem;
    `}

    padding: 1rem 0;

    align-self: center;
`;

const ProductContent = styled.div`
    flex: 1;

    max-width: 25rem;
    ${media.sm`
        max-width: 100%;
    `}

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    >* {
        padding-bottom: 1rem;
    }

    padding-top: 2rem;
    ${media.md`
        padding-left: 2rem;
    `}
    ${media.xs`
        padding-top: 0;
    `}
`;

const RedeemOptions = styled.div`
    width: 100%;
    display: flex;

    ${media.xs`
        margin-top: 1rem;
    `}
`;

const RedeemQty = styled(SelectQuantity)`
    flex: 1;
`;

const RedeemButton = styled(({ faded, ...rest }) => <Button {...rest} />)`
    flex: 1;
    background-color: #db0011;
    color: white;

    :disabled {
        background-color: #ccc;
    }
    
    :hover {
        background-color: #db0011;
    }
`;

const LoginButton = styled(({ faded, ...rest }) => <Button {...rest} />)`
    width: fit-content;
    background-color: #db0011;
    color: white;
    padding: 10px;

    :hover {
        background-color: #db0011;
    }

    ${media.xs`
        margin-top: 1rem;
    `}
`;

const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductDescription = styled(({ className, children, ...props }) => (
    <div className={className}>
        <DisplayText {...props}>
            {children}
        </DisplayText>
    </div>
))`
    overflow-y: auto;
`

const TabPanel = styled.div`
    height: 100%;
    >div {
        padding-top: 1rem;
    }
`;

const DetailsContainer = styled(TabPanel)`
    flex: 1;
    min-height: 22em;
`;

const TabsContainer = styled.div`  
    flex: 1;
    padding-bottom: 2rem;
`;

const TabHeaders = styled(Tabs)`
    flex: 1;
    color: #333333;
    .Mui-selected {
        font-weight: 700;
    }
`;
const TnCStyle = {
    whiteSpace: 'pre-wrap'
}

class ProductDetails extends Component {
    state = {
        quantity: 1,
        tab: 0
    }

    static type = 'PRODUCT_DETAILS'
    static dialogProps = {
        title: '',
        fullSize: true,
        size: {
            width: '65vw',
            height: '80vh'
        }
    }

    tryLogin() {
        this.loginOrRedeem();
    }

    tryAddCart() {
        this.loginOrRedeem();
    }

    loginOrRedeem() {
        const { cartQty, profile, addOrUpdate, toastSuccess, product, onClose } = this.props;
        const { quantity } = this.state;

        if (!!profile) {
            addOrUpdate(product.id, quantity);
            toastSuccess(`Successfully ${!!cartQty ? 'Updated in' : 'Added to'} Cart`);
        } else {
            DataService.login();
        }

        // Finally, close the dialog, if possible
        if (onClose) onClose();
    }

    componentDidMount() {
        const { cartQty } = this.props;

        if (!!cartQty) {
            this.setState({ quantity: cartQty });
        }
    }

    componentDidUpdate({ cartQty: oldCartQty }) {
        const { cartQty } = this.props;

        if (oldCartQty !== cartQty) {
            this.setState({ quantity: cartQty });
        }
    }

    renderDetails() {
        const { product } = this.props;

        return (
            <ProductInfo>
                <DisplayText size="medium" variant="strong">{product.name}</DisplayText>
                <DisplayText variant="strong">
                    <DisplayText color="primary" variant="strong" size="xlarge">
                        {`${product.cost} `}
                    </DisplayText>
                    <DisplayText color="primary" variant="strong">
                        points
                            </DisplayText>
                </DisplayText>
            </ProductInfo>
        )
    }

    renderActions() {
        const { cartQty, profile,  logonRedirection} = this.props;
        const quantity = this.state.quantity;

        return !!profile ? (
            <RedeemOptions>
                <RedeemQty value={quantity} onChange={val => this.setState({ quantity: val })} />
                <RedeemButton disabled={quantity <= 0} onClick={() => this.tryAddCart()}>
                    <DisplayText color="inherit" variant="strong">
                        {`${!!cartQty ? 'Update' : 'Add To'} Cart`}
                    </DisplayText>
                </RedeemButton>
            </RedeemOptions>
        ) : (
                <LoginButton onClick={() => {this.tryLogin(); logonRedirection(true)}}>
                    <DisplayText color="inherit" variant="strong">
                    LOGIN to ADD TO CART
                        </DisplayText>
                </LoginButton>
            )
    }

    render() {
        const { product } = this.props;
        const { tab } = this.state;

        return product ? (
            <DetailTabs>
                <DetailsContainer>
                    <Details>
                        {product.images ? (
                            <ProductImages>
                                <BannerCarousel banners={product.images} showZoom />
                            </ProductImages>
                        ) : null}
                        <ProductContent>
                            {this.renderDetails()}
                            <ProductDescription>{product.description}</ProductDescription>
                            {this.renderActions()}
                        </ProductContent>
                    </Details>
                </DetailsContainer>
                <TabsContainer>
                    <TabHeaders
                        variant="fullWidth"
                        value={tab}
                        onChange={(evt, newVal) => this.setState({ tab: newVal })}
                        aria-label="product details"
                    >
                        <Tab label="Description" />
                        <Tab label="T&C" />
                    </TabHeaders>
                    {tab === 0 ? (
                        <TabPanel>
                            <ProductDescription>
                                {product.longDescription}
                            </ProductDescription>
                        </TabPanel>
                    ) : null}
                    {tab === 1 ? (
                        <TabPanel>
                            <ProductDescription>
                                <span style={TnCStyle} dangerouslySetInnerHTML={{ __html: product.terms }}></span>
                            </ProductDescription>
                        </TabPanel>
                    ) : null}
                </TabsContainer>
            </DetailTabs>
        ) : null
    }
}

const mapStateToProps = ({ user, cart, catalogue: { selectedProduct } }) => ({
    cartQty: selectedProduct.id in cart.products ? cart.products[selectedProduct.id] : null,
    profile: user.profile,
    product: { ...selectedProduct, quantity: (selectedProduct.id in cart.products ? cart.products[selectedProduct.id] : 0) },
})

const mapDispatchToProps = dispatch => ({
    addOrUpdate: (id, qty) => dispatch(addOrUpdateCart(id, qty)),
    toastSuccess: message => dispatch(showToast({ message, variant: 'success' })),
    logonRedirection: logon => dispatch(isLogonRedirection(logon))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductDetails));