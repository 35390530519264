import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { DisplayText } from './display-text';

const SelectContainer = styled.div`
    //width: 100%;

    display: flex;

    align-items: center;
    justify-content: flex-start;

    min-width: 110px;
    width: 80%;
`;

const ManualButtons = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
`;

const ActionIcon = styled(Button)`
    padding: 0;
    width: fit-content;
    
    min-width: 2.25rem;

    :hover {
        color: #db0011;
        background: none;
    }
`;

export default class SelectQuantity extends Component {
    static propTypes = {
        min: PropTypes.number,
        max: PropTypes.number.isRequired
    }

    static defaultProps = {
        min: 0,
        max: 9999
    }

    render() {
        const { value, onChange, min, max, className, hideLabel } = this.props;
        return (
            <SelectContainer className={className}>
                <TextField
                    label={hideLabel ? "" : "Quantity"}
                    variant="outlined"
                    value={value}
                    onChange={({ target: { value } }) => (value >= min && value <= max) ? onChange(value) : null}
                />
                <ManualButtons>
                    <ActionIcon onClick={() => value < max ? onChange(parseInt(value) + 1) : null}>
                        <DisplayText color="inherit">+</DisplayText>
                    </ActionIcon>
                    <ActionIcon onClick={() => value > min ? onChange(parseInt(value) - 1) : null}>
                        <DisplayText color="inherit">-</DisplayText>
                    </ActionIcon>
                </ManualButtons>
            </SelectContainer>
        )
    }
}
