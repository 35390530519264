class _ExternalService {
    constructor() {
        this.domain = (process.env.REACT_APP_EXTERNAL_HOST || '');
        this.apiBase = (process.env.REACT_APP_EXT_API_PATH || '');
    }

    login() {
        // Redirect to SSO login
        window.location = `${this.domain}${process.env.REACT_APP_SSO_LOGIN_PATH}`;
    }

    logout() {
        // Redirect to SSO logout
        window.location = `${this.domain}${process.env.REACT_APP_SSO_LOGOUT_PATH}`;
    }

    async getUserProfile() {
        const profile = await this._dataRequest(`${this.apiBase}personal-details`, {
            method: 'GET'
        });

        return profile;
    }

    async getUserCards() {
        const { accountList } = await this._dataRequest(`${this.apiBase}accounts/domestic`, {
            method: 'GET'
        })

        return accountList;
    }

    async getCardDetails(card) {
        const { creditCardAccountDetail } = await this._dataRequest(`${this.apiBase}accounts/credit-cards/${card.accountIdentifier.productCategoryCode}-${card.accountIdentifier.accountNumber}`, {
            method: 'GET'
        })

        return creditCardAccountDetail;
    }

    async redeemProducts(card, products, orderId) {
        //console.log(orderId);
        const totalPoints = products.reduce((acc, curr) => acc + curr.total, 0);
        const body = {
            "cardRedemptionDetails": {
                "redeemInfoDetails": [
                    {
                        "accountNumberIndex": card.accountIdentifier.accountNumber,
                        "rewardPntsPrgmName": card.productDescription,
                        "availablePoints": card.bonusPoints,
                        "pointsToBeUsed": totalPoints
                    }
                ],
                "itemDetails": products.map(product => ({
                    "itemDescription": product.name,
                    "rewardsCode": product.rewardsCode,
                    "quantity": product.quantity,
                    "requiredPoints":product.total / product.quantity,
                    "pointsNeeded": product.total,
                    "requiredAmount": 0.0,
                    "amountNeeded": 0.0,
                    "accountNumberIndex": card.accountIdentifier.accountNumber
                })),
                "totalDebitedAmount": 0.0,
                "totalPointsNeeded": totalPoints,
                "totalPointsRedemed": totalPoints,
                // This HAS to be alphanumeric only
                "debitAccount": `${orderId}`
            }
        };

        console.log(body);
        const { responseInfo } = await this._dataRequest(`${this.apiBase}card-redemption`, {
            method: 'POST',
            body: body
        });

        return responseInfo.reasons && responseInfo.reasons[0] && responseInfo.reasons[0].type === 'SUCCESS';
    }

    async _dataRequest(path, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Content-Type': 'application/json',
            'X-HSBC-Chnl-Group-Member': 'HSBC',
            'X-HSBC-Chnl-CountryCode': 'IN',
            'X-HSBC-Channel-Id': 'OHI',
            'X-HSBC-Locale': 'en_IN'
        }

        if (typeof options.body === 'object') options.body = JSON.stringify(options.body);

        const result = await fetch(`${this.domain}${path}`, {
            headers,
            credentials: "include",
            ...options
        });

        try {
            this._checkStatus(result);
            return result.json();
        } catch (err) {
            return await this._handleError(err);
        }
    }

    async _handleError(err) {
        // Extracts and returns a rejected promise, with the error message (if any)
        if (!!err.response) {
            const { response } = err;
            const json = await response.json();

            if (json) return Promise.reject(json.message);
            else return Promise.reject(response.statusText);
        }

        return Promise.reject(err);
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            const error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}

const ExternalService = new _ExternalService();
export default ExternalService;