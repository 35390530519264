import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import RCTable from 'rc-table';
import 'rc-table/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearCart, removeFromCart, updateCart } from '../../store/actions/cart';
import { DisplayText } from '../display-text';
import SelectQuantity from '../select-qty';
import CartImage from '../cart-image';

const MAX_ITEM_QTY = 9999;

const CartContainer = styled.div`

`;

const CartRemove = styled(props => <div {...props}><FontAwesomeIcon icon={faTrash} /></div>)`
    cursor: pointer;
    color: #db0011;
    font-size: 0.9rem;

    opacity: 0.5;
    :hover {
        opacity: 1;
    }
`;

const CartHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const CartFooter = styled.div`
    text-align: left;
`;

const ClearAll = styled(props => <DisplayText size="small" variant="subdued" color="light" {...props} />)`
    cursor: pointer;
    :hover {
        color: #db0011;
    }
`;

class OrderCart extends Component {
    columns = [
        {
            title: 'Brand Logo', dataIndex: 'image', width: 75,
            render: (value) => (
                value ? <CartImage src={value} /> : null
            )
        },
        {
            title: 'Item Code', dataIndex: 'rewardsCode', width: 75,
            render: value => (
                <DisplayText color="light">{value}</DisplayText>
            )
        },
        {
            title: 'Item Name', dataIndex: 'name', width: 300,
            render: value => (
                <DisplayText color="light">{value}</DisplayText>
            )
        },
        {
            title: 'Quantity', dataIndex: 'quantity', width: 150,
            render: (value, row) => (
                value ? <SelectQuantity max={MAX_ITEM_QTY} value={value} onChange={val => this.updateQuantity(row.id, val)} /> : null
            )
        },
        {
            title: 'Points', dataIndex: 'cost', width: 100,
            render: value => (
                <DisplayText color="light">{value}</DisplayText>
            )
        },
        {
            title: 'Total', dataIndex: 'total', width: 100,
            render: (value, row) => (
                <DisplayText>{value}</DisplayText>
            )
        },
        {
            width: 25, dataIndex: 'skipRemove',
            render: (value, row) => (
                !!value ? null : <CartRemove onClick={() => this.props.removeCartItem(row.id)} />
            )
        }
    ]

    updateQuantity(id, quantity) {
        if (quantity > 0) {
            this.props.updateCartQty(id, quantity);
        } else if (quantity === 0) {
            this.props.removeCartItem(id);
        }
    }

    renderMobile(tableData) {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Item
                        </TableCell>
                        {/* <TableCell>

                        </TableCell> */}
                        <TableCell>
                            Quantity
                            </TableCell>
                        <TableCell>
                            Points
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((item, index) => (
                        <TableRow key={`mobile-cart-row-${index}`}>
                            {/* <TableCell>
                                {item.image ? <CartImage src={item.image} /> : null}
                            </TableCell> */}
                            <TableCell>
                                {item.name ? <DisplayText color="light">{item.name}</DisplayText> : null}
                            </TableCell>
                            <TableCell style={{ padding: 0, textAlign: "center" }}>
                                {item.isTotal ? <DisplayText color="light">{item.cost}</DisplayText> : null}
                                {item.quantity ? <SelectQuantity max={MAX_ITEM_QTY} value={item.quantity} onChange={val => val > 0 && this.props.updateCartQty(item.id, val)} /> : null}
                            </TableCell>
                            <TableCell style={{ padding: 0, textAlign: "center" }}>
                                <DisplayText color="light">{item.isTotal ? item.total : item.cost}</DisplayText>
                            </TableCell>
                            <TableCell style={{ padding: 0, textAlign: "center" }}>
                                {item.isTotal ? null : <CartRemove onClick={() => this.props.removeCartItem(item.id)} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    verifyCurrentStep() {
        const { products, verifyStep, selectedCard, canProceed } = this.props;
        let err = '';

        const totalCost = products.reduce((acc, curr) => acc + curr.total, 0);

        // Available points, or total cost changed
        if (selectedCard.bonusPoints < totalCost) {
            err = `Sorry! You do not have sufficient reward points on your credit card account. Your available reward point balance is ${selectedCard.bonusPoints} points. Please remove some items from the cart and try again.`;
        }

        //Check the number of unique products in the cart
        if (products.length >= 5) {
            err = `Important: You can add a maximum of 4 items(with multiple quantity) in a single order. Please submit a separate order for additional items.`;
        }

        // Product quantity changed
        if (products.length <= 0) {
            err = 'no-text';
        }

        if (err && canProceed) {
            verifyStep(false, err);
        } else if (!err && !canProceed) {
            verifyStep(true);
        }
    }

    componentDidUpdate() {
        this.verifyCurrentStep();
    }

    render() {
        const { products, cartClear } = this.props;

        // Append the total as a row
        const tableData = [...products];
        tableData.push({
            cost: 'Total',
            skipRemove: true,
            isTotal: true,
            total: products.reduce((a, p) => a + p.total, 0)
        });

        // Products are only the ones mapped to the current card

        // Cart contains on the product ID mapped to qty

        // Profile contains all the available points

        return (
            <CartContainer>
                <CartHeader>
                    <DisplayText size="large" variant="strong">Cart</DisplayText>
                    <ClearAll onClick={() => cartClear()}>clear all</ClearAll>
                </CartHeader>
                <Hidden only='xs'>
                    <RCTable
                        columns={this.columns}
                        data={tableData}
                        emptyText={<DisplayText color="light">No Products In Your Cart</DisplayText>}
                        rowKey='id'
                    />
                </Hidden>
                <Hidden smUp={true}>
                    {this.renderMobile(tableData)}
                </Hidden>
                <CartFooter>
                    <DisplayText size='small' variant='light' color='subdued'>
                        The redemption of any product in this catalogue is subject to The Hongkong and
                        Shanghai Banking Corporation Limited prevailing Terms and Conditions governing
                        the Bank's Rewards Programme. In particular, please note that the Bank makes no
                        warranty whatsoever as to the quality of fitness for any particular purpose of any
                        product shown in this catalogue. Any dispute you may have concerning any
                        product shall be settled with the relevant service establishment and/or supplier
                        which supplied the product. The Bank will bear no responsibility for resolving such
                        disputes or for the dispute itself.
                    </DisplayText>
                    <br />
                    <br />
                    <DisplayText size='small' variant='strong' color='black'>
                        Please note:
                    </DisplayText>
                    <ul>
                        <li>
                            <DisplayText size='small' variant='light' color='subdued'>
                                No delivery will be made to P.O. Box addresses or to addresses outside India
                            </DisplayText>
                        </li>
                        <li>
                            <DisplayText size='small' variant='light' color='subdued'>
                                Kindly select products from the applicable credit card category under the
                            rewards catalogue to ensure successful redemption
                        </DisplayText>
                        </li>
                        <li>
                            <DisplayText size='small' variant='light' color='subdued'>
                                Products requested against redemption of points will be delivered to your
                                registered mailing address as per bank records. Should you wish to update
                                your mailing address then please call PhoneBanking OR visit your nearest
                                HSBC branch for assistance, prior to placing your redemption request.
                        </DisplayText>
                        </li>
                    </ul>
                </CartFooter>
            </CartContainer >
        )
    }
}

const mapStateToProps = ({ user }) => ({
    profile: user.profile
})

const mapDispatchToProps = dispatch => ({
    updateCartQty: (id, qty) => dispatch(updateCart(id, qty)),
    removeCartItem: id => dispatch(removeFromCart(id)),
    cartClear: () => dispatch(clearCart())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderCart);