import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { hideToast } from '../store/actions/app';

const Toast = styled(Snackbar)`
    
`;

const MessageContainer = styled(({ variant, ...props }) => <SnackbarContent {...props} />)`

    ${props => {
        switch (props.variant) {
            case 'success':
                return css`
                    background-color: green;
                `;
            case 'error':
                return css`
                    background-color: firebrick;
                `;
            case 'warning':
                return css`
                    background-color: #FFBF00;
                `;
            default:
        }
    }};
`;

const Message = styled.span`

`;

const VariantIcon = styled(FontAwesomeIcon)`
    margin-right: 1rem;
`;

const MessageIcon = ({ variant }) => {
    let icon = null;
    switch (variant) {
        case 'success':
            icon = faCheckCircle;
            break;
        case 'warning':
        case 'error':
            icon = faExclamationCircle;
            break;
        default:
            icon = null;
    }

    if (icon) return <VariantIcon variant={variant} icon={icon} />
    else return null;
}

class ToastNotification extends Component {
    render() {
        const { toastProps, close } = this.props;
        const { message, variant, duration, ...props } = typeof toastProps === 'string' ? { message: toastProps } : toastProps || {};

        return (
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={!!toastProps}
                autoHideDuration={duration !== undefined ? duration : 1000}
                onClose={() => close()}
            >
                <MessageContainer
                    variant={variant}
                    aria-describedby="app-toast"
                    message={
                        <Message id="app-toast">
                            <MessageIcon variant={variant} />
                            {message}
                        </Message>
                    }
                    {...props}
                />
            </Toast>
        )
    }
}

const mapStateToProps = ({ app }) => ({
    toastProps: app.toast
})

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(hideToast())
})

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotification);