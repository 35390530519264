import { Grid, useMediaQuery } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BannerCarousel from '../components/catalogue/banner-carousel';
import ProductList from '../components/catalogue/product-list';
import DataService from '../services/data-service';
import { isLoading, setBreadcrumbs, showSidebar } from '../store/actions/app';
import { clearAllFilters, refreshCatalogue, setProducts } from '../store/actions/catalogue';
import { media } from '../utils/styles';

const CatalogueContainer = styled(({ showFilters, toggleSidebar, ...props }) => {
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    if (isDesktop) showFilters();

    return (
        <Grid {...props} onClick = { () => (isDesktop ? toggleSidebar('filters') : toggleSidebar('')) } />
    )
})`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-wrap: wrap;
`;

const CatalogueBanner = styled(BannerCarousel)`
    height: 20rem;
`;

const ProductCatalogue = styled(ProductList)`
    ${media.xs`
        margin-top: 1rem;
    `}

    ${media.sm`
        margin-top: 1rem;
    `}

    ${media.lg`
        margin-top: 0;
    `}
    
    ${props => !props.filtersVisible && media.xs`
        margin-top: 5rem;
    `}

    ${props => !props.filtersVisible && media.sm`
        margin-top: 5rem;
    `}
`;

class Catalogue extends Component {
    state = {
        banners: []
    }

    async fetchProducts(code) {
        const { productsFetched } = this.props;
        const products = await DataService.getProductsForCard(code);

        productsFetched(code, products);
    }

    async fetchOffers(code) {
        const bannerOffers = await DataService.getBannerOffersForCard(code);

        this.setState({ banners: bannerOffers });
    }

    refreshCatalogue(code) {
        const { clearFilters, catalogueCard, doRefresh, refreshed, showLoading } = this.props;

        // Loading
        showLoading(true);

        // Clear filters on refresh
        clearFilters();

        // Followed by the offers
        // await this.fetchOffers(code !== undefined ? code : catalogueCard);

        // Fetch products
        setTimeout(async () => {
            await this.fetchProducts(code !== undefined ? code : catalogueCard);
            // Flush any pending refresh
            if (doRefresh) refreshed(true);

            // Complete
            showLoading(false);
        }, 4)
    }

    componentDidMount() {
        const { selectedCard, showBreadcrumbs } = this.props;
        const { match: { params: { code: cardCode } } } = this.props;

        if (selectedCard) {
            // Show the breadcrumb trail
            showBreadcrumbs([{ name: 'Cards', link: '/' }, { name: `Welcome to ${selectedCard.productDescription} catalogue` }]);
        }

        // Then refresh
        this.refreshCatalogue(cardCode);

    }

    componentDidUpdate({ catalogueCard: oldCard }) {
        const { catalogueCard, doRefresh } = this.props;
        const { match: { params: { code: cardCode } } } = this.props;

        if (doRefresh || oldCard !== catalogueCard || catalogueCard !== cardCode) {
            this.refreshCatalogue(cardCode);
        }
    }

    render() {
        const { className, filtersVisible, showFilters, toggleSidebar } = this.props;
        const { banners } = this.state;

        return (
            <CatalogueContainer showFilters={showFilters} className={className} toggleSidebar={toggleSidebar}>
                {banners && banners.length > 0 ? (
                    <CatalogueBanner banners={banners} />
                ) : null}
                <ProductCatalogue filtersVisible={filtersVisible} />
            </CatalogueContainer >
        )
    }
}

const mapStateToProps = ({ user, catalogue }) => ({
    selectedCard: user.cards ? user.cards[catalogue.cardCode] : null,
    catalogueCard: catalogue.cardCode,
    doRefresh: catalogue.forceRefresh,
    filtersVisible: catalogue.filtersVisible
})

const mapDispatchToProps = dispatch => ({
    productsFetched: (cardCode, products) => dispatch(setProducts(cardCode, products)),
    refreshed: done => dispatch(refreshCatalogue(!done)),
    showLoading: loading => dispatch(isLoading(loading)),
    clearFilters: () => dispatch(clearAllFilters()),
    showFilters: () => dispatch(showSidebar('filters')),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props)),
    showBreadcrumbs: parts => dispatch(setBreadcrumbs(parts))
})

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);