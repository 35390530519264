import { faAngleDown, faAsterisk, faCreditCard, faFilter, faQuestion, faSearch, faShoppingCart, faTimesCircle, faUser, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, ClickAwayListener, Grid, Hidden, Popper, Slide, Toolbar, useMediaQuery } from '@material-ui/core';
import Fuse from 'fuse.js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CardSelection from '../components/card-selection';
import { DisplayText } from '../components/display-text';
import { SimpleLink } from '../components/simple-link';
import SplitView from '../components/split-view';
import DataService from '../services/data-service';
import { hideSidebar, showSidebar, isLogonRedirection } from '../store/actions/app';
import { clearFilter, setFilter } from '../store/actions/catalogue';
import { media } from '../utils/styles';
import { isMobile } from 'react-device-detect';

const SCROLL_SWITCH_Y = 150;
// This SHOULD be greater than the total height difference 
// from all the elements that will hide on scroll
const SCROLL_NEXT_SWITCH_OFFSET = 90;

const STATIC_LINKS = [
    {
        display: 'FAQ',
        href: '/faq',
        icon: <FontAwesomeIcon icon={faQuestion} />
    },
    {
        display: 'T&C',
        href: '/tnc',
        icon: <FontAwesomeIcon icon={faAsterisk} />
    }
];

const SEARCHABLE_PAGES = [
    {
        slug: 'catalogue',
        searchConfig: props => ({
            list: props.products,
            options: {
                keys: ['name', 'catalogueName', 'brandName', 'category'],
            }
        })
    }
];

const AppHeader = styled(AppBar)`
    display: flex;
    flex-direction: column;

    box-shadow: 0px 2px 2px #d7d8d6;
`;

const HelpBar = styled(({ isMini, ...props }) => <div {...props}></div>)`
    height: 2.25rem;
    
    transition: 200ms height;
    ${props => props.isMini && css`
        height: 0;
    `}
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0.625rem;
`;

const NavBar = styled(({ isMini, children, ...props }) => (
    <Toolbar {...props}>
        <SplitView spaced justify="space-between">
            {children}
        </SplitView>
    </Toolbar>
))`
    height: 5rem;
    
    transition: 200ms height;
    ${props => props.isMini && css`
        height: 2.5rem;
    `}
    background-color: white;

    justify-content: center;
    align-items: center;

    &.mini {
        height: 2rem;
    }
`;

const StaticLinks = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const UserLinks = styled.div`
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    >* {
        height: 100%;
        display: inline-flex;
        align-items: center;
    }
`;

const HelpLink = styled.a`
    color: whitesmoke;
    font-size: 0.8rem;
    font-weight: 700;
    margin-left: 1rem;
    text-decoration: none;
`;

const LogoContainer = styled(({ className }) => (
    <div className={className}>
        <a href="/"><img src="/images/01_HSBC_MASTERBRAND_LOGO_RGB.svg" alt="HSBC-logo" /></a>
    </div>
))`
    height: 100%;
    img {
        height: 100%;
        max-width: 10rem;
        ${media.xs`
            max-width: 7rem;
        `}
    }
`;

const ActionTrigger = styled(({ className, isActive, children, ...rest }) => (
    <button className={className} {...rest}>
        <FontAwesomeIcon icon={faAngleDown} />
        <div>
            {children}
        </div>
    </button>
))`
    ${props => props.isActive && css`
        color: #db0011;
    `}

    cursor: pointer;

    outline: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0 0.5rem;

    display: inline-flex;
    align-items: center;

    max-width: 3rem;
    >:first-child {
        padding-right: 0.25rem;
    }
`;

const CardsTirgger = styled(({ className, card, ...rest }) => (
    <ActionTrigger className={className} {...rest}>
        {card ? (
            <img src={card.image} alt="select card"/>
        ) : (
                <FontAwesomeIcon icon={faCreditCard} color="grey" size='2x' />
            )}
    </ActionTrigger>
))`
    img {
        height: 30px;
        width: 45px;
    }

    svg {
        color: white;
    }
`;

const FilterTirgger = styled(props => (
    <ActionTrigger {...props}>
        <FontAwesomeIcon icon={faFilter} />
    </ActionTrigger>
))`
    height: 2rem;
    line-height: 2rem;
    font-size: 0.9rem;
    color: #d7d8d6;

    ${props => props.isActive ? css`
        color: #db0011;
    ` : css`
        :hover {
            color: #767676;
        }
    `}
`;

const CardPoints = styled.div`
    margin-right: 1rem;
    >:first-child {
        margin-right: 0.5rem;
    }
`;

const DropMenu = styled(({ className, children, ...rest }) => (
    <Grid className={className} container justify="center">
        <Grid item xs={12} md={8}>
            {children}
        </Grid>
    </Grid>
))`
    position: fixed;
    z-index: 100;
    top: 5rem;

    max-height: 95vh;
    overflow: hidden auto;

    padding: 0;

    >:first-child {
        background-color: white;
        ${media.md`
            padding: 0 1rem;
        `}
    }

    transform: scaleY(0);
    transform-origin: center top;
    transition: transform 200ms ease-out;
    ${props => props.visible && css`
        transform: scaleY(1);
    `}

    >:first-child {
        box-shadow: 0px 2px 4px #d7d8d6;
    }
`;

const CardsMenu = styled(DropMenu)`

    >:first-child {
        background-color: whitesmoke;
        padding: 3rem 1rem;
        border: 1px solid #999999;
        border-radius: 4px;
    }
`;

const SearchContainer = styled(({ className, searchIndex, searchFilter, ...props }) => {
    const [isActive, setActive] = React.useState(false);
    const [value, setValue] = React.useState('');
    return (
        <div className={`${className} ${isActive ? 'active' : ''}`}>
            <IconContainer onClick={() => setActive(true)}>
                <FontAwesomeIcon icon={faSearch} color='gray' />
            </IconContainer>
            <SearchField
                isActive={isActive}
                value={value}
                placeholder="Search…"
                onChange={evt => {
                    setValue(evt.target.value);
                    searchFilter({ index: searchIndex, term: evt.target.value });
                }}
                onMouseOut={() => !!value ? false : setActive(false)}
                {...props}
            />
            {isActive ? (
                <FontAwesomeIcon onClick={() => {
                    setValue('');
                    searchFilter();
                    setActive(false);
                }} className="clear" icon={faTimesCircle} color='gray' />
            ) : null}
        </div>
    )
})`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;

    &.active {
        flex: 1;

        & ~ * {
            display: none;
        }
    }

    .clear {
        position: absolute;
        right: 2px;
    }
`;

const IconContainer = styled.div`
    height: 100%;
    margin-right: -20px;
    z-index: 2;

    svg {
        vertical-align: baseline;
        height: 1rem;
    }
`;

const SearchField = styled.input`
    width: 0;
    height: 2rem;
    transition: width 200ms;

    line-height: 2rem;
    font-size: 0.875rem;

    border: none;
    outline: none;

    ${props => props.isActive && css`
        background-color: rgba(233, 233, 233, 0.25);
        width: 100%;
    `}

    padding-left: 30px;
    border-radius: 5px;
`;

const NavStart = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >* {
        margin-right: -14px;
    }
`;

const NavEnd = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >* {
        margin-left: 1rem;
        ${media.xs`
            margin-left: 0.5rem;
        `}
    }

    ${media.xs`
        padding-right: 0.5rem;
    `}
`;

const UserActions = styled(({ className, cartCount, onAction }) => (
    <UserAction
        className={className}
        icon={faShoppingCart}
        color={cartCount>0 ?  "#db0011" : "#d7d8d6"}
        notif={cartCount}
        onClick={() => onAction("/checkout")}
    />
))`
    svg {
        font-size: 1.2rem;
        line-height: 2rem;
    }
`;

// const OrderHistory = styled(({ onAction }) => (
//     <UserAction
//         icon={faHistory}
//         color="#888"
//         onClick={() => onAction("/order-history")}
//     />
// ))`
//     svg {
//         font-size: 1.2rem;
//         line-height: 2rem;
//     }
// `;

const CartNotif = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.25rem;
    margin-top: -0.25rem;

    background-color: #767676; /*rgba(219, 0, 17, 0.5); */
    border-radius: 5px;
    padding: 0 1px;
    min-height: 1rem;
    min-width: 1rem;

    >span {
        color: white;
        font-weight: 700;
        vertical-align: top;
        font-size: 0.6rem !important;
        line-height: 1rem  !important;
    }
`;

const LoginButton = styled.div`
    cursor: pointer;
    width: 5.5rem;
    height: 100%;

    background-color: #db0011;

    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        color: white;
    }
`;

const UserAction = styled(({ className, notif, onClick, ...props }) => (
    <SimpleLink className={className} onClick={onClick}>
        {notif ? (
            <CartNotif>
                <DisplayText>
                    {notif}
                </DisplayText>
            </CartNotif>
        ) : null}
        <DisplayText>
            <FontAwesomeIcon {...props} />
        </DisplayText>
    </SimpleLink>
))`
    &:hover {
        svg {
            color: #db0011;
        }
    }

    svg {
        font-size: 1.2rem;
        line-height: 2rem;
    }
`;

const DetailItems = styled.div`
    display: flex;
    align-items: center;

    >* {
        cursor: pointer;
        margin-right: 0.75rem;

        svg {
            height: 20px;
            margin-bottom: 1px;
            margin-left: 10px;
        }
        
        span {
            margin-right: 0.25rem;
        }
    }
`;

const UserPopDown = styled(withRouter(({ className, history, toggle, profile, anchor }) => {
    return (
        <Popper
            transition
            open={!!anchor}
            anchorEl={anchor}
            className={className}
        >
            {({ TransitionProps }) => (
                <Slide {...TransitionProps} timeout={100}>
                    <ClickAwayListener onClickAway={toggle}>
                        <div>
                            {/* <SimpleLink disabled onClick={() => history.push('/profile')}>
                                <DisplayText size="small" color="light">Profile</DisplayText>
                                </SimpleLink>*/}
                            <SimpleLink onClick={() => history.push('/wishlist')}>
                                <DisplayText size="small" color="light">Wishlist</DisplayText>
                            </SimpleLink>  
                            <SimpleLink onClick={() => history.push('/order-history')}>
                                <DisplayText size="small" color="#333">Order History</DisplayText>
                            </SimpleLink>
                        </div>
                    </ClickAwayListener>
                </Slide>
            )}
        </Popper>
    )
}))`
    z-index: 9998;

    >div {
        display: flex;
        flex-direction: column;
        >* {
            margin: 0.5rem 0rem;
        }

        margin-top: 0.25rem;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 1px 2px #d7d8d6;

        button:hover span {
            text-align: left;
            color: #db0011;
        }
    }
`

const UserDetails = ({ className, profile, isMini }) => {
    const { personalInformation } = profile;
    const { customerName } = personalInformation || {};
    return (
        <DetailItems className={className}>
            {customerName ? (
                <div title={`${customerName.title || ''} ${customerName.firstName} ${customerName.lastName}`}>
                    <DisplayText size='small' color='white'>
                    { isMobile ? '' :` Welcome ${customerName.title || ''} ${customerName.firstName} ${customerName.lastName}`}
                    </DisplayText>
                </div>
            ) : null}
        </DetailItems >
    )
}

const UserIcon = ({className, profile, isMini}) => {
    const { personalInformation } = profile;
    const { customerName } = personalInformation || {};
    const [anchor, setAnchor] = React.useState(null);
    React.useEffect(() => {
        if (isMini) setAnchor(null);
    }, [isMini])
    return (
        <DetailItems className={className}>
            {customerName ? (
                <div
                    onClick={({ target }) => setAnchor(anchor ? null : target)}
                    title={`${customerName.title || ''} ${customerName.firstName} ${customerName.lastName}`}
                >
                    <FontAwesomeIcon icon={faUser} color='gray' />
                </div>
            ) : null}
            <UserPopDown profile={profile} anchor={anchor} toggle={() => setAnchor(null)} />
        </DetailItems >
    )
}

const FilterTrigger = ({ filtersVisible, toggleSidebar }) => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1024px)');
    return isTabletOrMobile ? (
        <FilterTirgger
            isActive={filtersVisible}
            onClick={() => toggleSidebar(filtersVisible ? '' : 'filters')}
        />
    ) : null
}

class Header extends Component {
    state = {
        searchIndex: null,
        searchSlug: '',
        isSearching: false,

        cardsVisible: false,
        filtersVisible: false,

        canSearch: false,
        canSelectCard: false,
        canViewActions: false,
        // canViewHistory: false,
        onCatalogue: false,

        miniHeader: false
    }

    constructor(props) {
        super(props);

        this._ticking = false;
        this._lastScrollY = 0;
        this._scrollAmount = 0;
        this._nextScrollSwitch = SCROLL_SWITCH_Y;
        this._onScroll = this._onScroll.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    /**
     * Callback for our scroll event - just
     * keeps track of the last scroll value
     */
    _onScroll() {
        this._scrollAmount = window.scrollY - this._lastScrollY;
        this._lastScrollY = window.scrollY;
        this._requestTick();
    }

    /**
     * Calls rAF if it's not already
     * been done already
     */
    _requestTick() {
        if (!this.ticking) {
            requestAnimationFrame(this.handleScroll);
            this._ticking = true;
        }
    }

    handleScroll() {
        const { miniHeader } = this.state;
        let isMini = miniHeader;

        if (window.pageYOffset < this._nextScrollSwitch && isMini) {
            // We're above the switch point, and are minimized
            isMini = false;
            this._nextScrollSwitch += SCROLL_NEXT_SWITCH_OFFSET;
        } else if (window.pageYOffset > this._nextScrollSwitch && !isMini) {
            // We're below the switch point, and are not minimized
            isMini = true;
            this._nextScrollSwitch -= SCROLL_NEXT_SWITCH_OFFSET;
        }

        // Only set state if the direction has changed
        if (miniHeader !== isMini) {
            this.setState({ miniHeader: isMini });
        }
        this._ticking = false;
    }

    setupSearchIndex() {
        const { location } = this.props;
        const { searchSlug } = this.state;
        const slugs = location.pathname.split('/').filter(slug => !!slug);

        // Check if the current page is searchable, and not already indexed
        const page = SEARCHABLE_PAGES.find(page => page.slug === slugs[0]);
        if (page && page.slug !== searchSlug) {
            const { list, options } = page.searchConfig(this.props);
            const fuse = new Fuse(list, options);

            this.setState({ searchIndex: fuse, searchSlug: page.slug });
        }

        if (!page && !!searchSlug) {
            // If there's no indexable page, but a slug is set
            // reset it
            this.setState({ searchIndex: null, searchSlug: '' });
        }
    }

    setupListeners(attach = true) {
        window[`${attach ? 'add' : 'remove'}EventListener`]('scroll', this._onScroll, false);
    }

    setupElementVisibility() {
        const { location, profile, card, cartCount } = this.props;
        const slugs = location.pathname.split('/').filter(slug => !!slug);

        // Check if the current page is searchable
        const canSearch = !!SEARCHABLE_PAGES.find(page => page.slug === slugs[0]);
        this.setState({
            canViewActions: !!profile && slugs[0] !== 'checkout' && card,
            canViewHistory: !!profile,
            onCatalogue: slugs[0] === 'catalogue',
            canSelectCard: !!slugs[0],
            canSearch
        });
    }

    redirectTo(path) {
        const { location, history } = this.props;

        if (location.pathname !== path) {
            history.push(path);
        }
    }

    componentDidUpdate({ location: { pathname: oldPath }, profile: oldProfile }) {
        const { location, profile: newProfile } = this.props;
        const { pathname: newPath } = location;

        if (newPath !== oldPath || oldProfile !== newProfile) {
            // Try an setup the search index on each update
            // This method internally optimizes when to re-index
            this.setupSearchIndex();

            this.setupElementVisibility();
        }
    }

    componentDidMount() {
        this.setupSearchIndex();

        this.setupElementVisibility();

        this.setupListeners(true);
    }

    componentWillUnmount() {
        this.setupListeners(false);
    }

    render() {
        // canViewHistory : to show history icon (hidden)
        const { miniHeader, cardsVisible, canSelectCard, canViewActions,canSearch, onCatalogue, searchIndex } = this.state;
        const { profile, searchFilter, cartCount, filtersVisible, toggleSidebar, card, history, logonRedirection } = this.props;

        return (
            <>
                <AppHeader position="sticky">
                    <HelpBar isMini={miniHeader}>
                        <StaticLinks>
                            {
                                STATIC_LINKS.map((link, index) => (
                                    <HelpLink key={`top-link-${index}`} href={link.href} onClick={() => history.push(link.href)}>
                                        {link.display}
                                    </HelpLink>
                                ))
                            }
                        </StaticLinks>
                        <UserLinks>
                            {!!profile ? (
                                <UserDetails profile={profile} isMini={miniHeader} />
                            ) : null}
                            {card && canSelectCard ? (
                                <>
                                    {card.bonusPoints >= 0 ? (
                                        <CardPoints>
                                            <DisplayText size='small' color='primary' variant='strong'>
                                                Points:
                                            </DisplayText>
                                            <DisplayText size='small' color='white' variant='regular'>
                                                {card.bonusPoints.toLocaleString()}
                                            </DisplayText>
                                        </CardPoints>
                                    ) : null}
                                </>
                            ) : null}
                            {!!profile ?
                                (
                                    <LoginButton onClick={() => DataService.logout()}>
                                        <DisplayText size='small'>Log out</DisplayText>
                                    </LoginButton>
                                ) : (
                                    <LoginButton onClick={() => {DataService.login(); logonRedirection(true)}}>
                                        <DisplayText size='small'>Log on</DisplayText>
                                    </LoginButton>
                                )
                            }
                        </UserLinks>
                    </HelpBar>
                    <NavBar isMini={miniHeader}>
                        <NavStart>
                            <LogoContainer />
                        </NavStart>
                        <NavEnd>
                        {card && canSelectCard ? (
                                <>
                                    <CardsTirgger card={card} isActive={cardsVisible} onClick={() => this.setState({ filtersVisible: false, cardsVisible: !cardsVisible })} />
                                </>
                            ) : null}
                            {!!profile ? (
                                <UserIcon profile={profile} isMini={miniHeader} />
                            ):null}
                            {canSearch ? (
                                <SearchContainer
                                    searchFilter={searchFilter}
                                    searchIndex={searchIndex}
                                    onFocus={() => this.setState({ isSearching: true })}
                                    onBlur={() => this.setState({ isSearching: false })}
                                />
                            ) : null}
                            {onCatalogue ? (
                                <FilterTrigger filtersVisible={filtersVisible} toggleSidebar={toggleSidebar} />
                            ) : null}
                            {canViewActions ? (
                                <UserActions cartCount={cartCount} onAction={this.redirectTo.bind(this)} />
                            ) : null}
                            {/* {canViewHistory ? (
                                <OrderHistory onAction={this.redirectTo.bind(this)}/>
                            ) : null} */}
                        </NavEnd>
                    </NavBar>
                </AppHeader>
                <CardsMenu visible={cardsVisible}>
                    <CardSelection onSelect={() => this.setState({ cardsVisible: false })} />
                </CardsMenu>
            </>
        )
    }
}

const mapStateToProps = ({ user, app, catalogue, cart }) => ({
    profile: user.profile,
    products: Object.values(catalogue.products),
    cartCount: Object.values(cart.products).reduce((acc, curr) => acc + parseInt(curr), 0),
    // card: user.cards ? user.cards[catalogue.cardCode] : {},
    card: user.selectedCard,
    filtersVisible: app.sidebar.type === 'filters'
})

const mapDispatchToProps = dispatch => ({
    searchFilter: config => dispatch(config ? setFilter('search', config) : clearFilter('search')),
    toggleSidebar: type => dispatch(!!type ? showSidebar(type) : hideSidebar()),
    logonRedirection: logon => dispatch(isLogonRedirection(logon))
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));