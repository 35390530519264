import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { DisplayText } from '../display-text';
import { WarningButton } from './warning';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { uid } from 'react-uid';
import CartImage from '../cart-image';
import DataService from '../../services/data-service';

const OrderDetailsContent = styled.div`
    width: 100%;
`;

const DetailsContainer = styled.div`
    padding-top: 1rem;
`;

const ItemsContainer = styled.div`
    margin-top: 2rem;
`;

const DetailsLine = styled.div`
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DetailsLineItem = styled(({ label, ...props }) => <DisplayText {...props} />)`
    flex: 2;
    text-align: left;

    ${props => props.label && css`
        flex: 1;
        text-align: left;
    `}
`;

export default class OrderDetails extends Component {
    state = {
        items: null
    }

    static type = 'ORDER_DETAILS'
    static dialogProps = {
        fullSize: true,
        size: {
            width: '65vw',
            height: '80vh'
        },
        actions: ({ onClose }) => (
            <>
                <WarningButton text='Close' color='primary' onClick={() => onClose(true)} />
            </>
        )
    }

    async componentDidMount() {
        // const { clientProductCode, cart, transactionRef } = this.props;
        // // const items = [];
        // const items = cart;
        
        // console.log(items);
        // Fetch details for each product in the cart
        // for (let item of cart) {
        //     const details = await DataService.getProductDetails(clientProductCode, item.productId);
        //     if (details) {
        //         details.quantity = item.quantity;

        //         items.push(details);
        //     }
        // }

        // this.setState({items: items});
    }

    render() {
        const { transactionRef, createdAt, cart } = this.props;
        console.log(cart);
        return (
            <OrderDetailsContent>
                <DetailsContainer>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Transaction ID</DetailsLineItem>
                        :
                        <DetailsLineItem>{transactionRef}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Status</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.status}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Order Placed</DetailsLineItem>
                        :
                        <DetailsLineItem>{new Date(createdAt).toLocaleDateString()}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Item Name</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.product_name}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Points Per Unit</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.final_landed_price}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Quantity</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.quantity}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Product Code</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.global_code}</DetailsLineItem>
                    </DetailsLine>
                    <DetailsLine>
                        <DetailsLineItem variant="strong" size="small" color="#333" label>Remarks</DetailsLineItem>
                        :
                        <DetailsLineItem>{cart.remarks}</DetailsLineItem>
                    </DetailsLine>
                </DetailsContainer>
                {/* <ItemsContainer>
                    <DisplayText size="medium" variant="strong">Items</DisplayText>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody> */}
                            {/* {cart.map((item, index) => ( */}
                                {/* <TableRow> */}
                                    {/* <TableCell>{item.image ? <CartImage src={item.image} /> : null}</TableCell> */}
                                    {/* <TableCell>{cart.product_name}</TableCell>
                                    <TableCell>x{cart.quantity}</TableCell>
                                    <TableCell>{cart.remarks}</TableCell>
                                </TableRow> */}
                            {/* ))} */}
                        {/* </TableBody>
                    </Table>
                </ItemsContainer> */}
                <br/>
                <DisplayText size='small' variant='strong' color='black'>
                    Note: Items will be delivered to your credit card statement mailing address updated in the Bank records. Electronic gift vouchers will be delivered only via email/SMS on the registered email ID and mobile number below 
                </DisplayText>
            </OrderDetailsContent>
        )
    }
}
