import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uid } from 'react-uid';
import styled from 'styled-components';
import { SimpleLink } from './simple-link';
import SplitView from './split-view';
import { DisplayText } from './display-text';

const BCContainer = styled.div`
    margin-top: 1rem;
    margin-left: 1rem;
`;

const BCLink = styled(SimpleLink)`
    text-align: left;
    padding: 0;
    >svg {
        font-size: 0.9rem;
        margin: 0 0.5rem;
        color: #db0011;
    }

    >span {
        color: #333;
    }
`;

function Breadcrumbs({ parts, history }) {
    return parts ? (
        <BCContainer>
            <SplitView alignItems="left">
                {parts.map((part, index) => (
                    <BCLink key={uid(part, index)} underline={!!part.link} onClick={() => !!part.link ? history.push(part.link) : false}>
                        {index > 0 ? <FontAwesomeIcon icon={faChevronRight} /> : null}
                        <DisplayText variant={index === (parts.length - 1) ? 'strong' : 'regular'}>{part.name}</DisplayText>
                    </BCLink>
                ))}
            </SplitView>
        </BCContainer>
    ) : null;
}

const mapStateToProps = ({ app }) => ({
    parts: app.breadcrumbs
})

export default connect(mapStateToProps)(withRouter(Breadcrumbs));
