import { catalogue as actionTypes } from '../actions/actionTypes';

const initialState = {
    cardCode: null,
    forceRefresh: false,
    selectedProduct: null,
    products: {},
    filters: {},
    pages: {},
    filtersVisible: true
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SELECT_PRODUCT:
            return {
                ...state,
                selectedProduct: payload
            }

        case actionTypes.SET_ACTIVE_CARD:
            return {
                ...state,
                cardCode: payload
            }
        case actionTypes.SHOW_FILTERS:
            return {
                ...state,
                filtersVisible: true
            }
        case actionTypes.HIDE_FILTERS:
            return {
                ...state,
                filtersVisible: false
            }
        case actionTypes.REFRESH_LIST:
            return {
                ...state,
                forceRefresh: payload
            }

        case actionTypes.RESET_PRODUCT:
            return {
                ...state,
                selectedProduct: null
            }

        case actionTypes.SET_PRODUCTS:
            return {
                ...state,
                products: { ...payload }
            }

        case actionTypes.UPDATE_PRODUCT:
            const updated = { ...state.selectedProduct, ...payload };
            return {
                ...state,
                selectedProduct: { ...updated },
                products: { ...state.products, [updated.id]: updated }
            }

        case actionTypes.SET_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.key]: payload.value
                }
            }

        case actionTypes.CLEAR_FILTER:
            delete state.filters[payload];

            return {
                ...state,
                filters: { ...state.filters }
            }
        case actionTypes.CLEAR_ALL_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state
    }
}
