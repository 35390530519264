import { Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ControlledLabelLeft from './control-label-left';

export default class SingleSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        options: PropTypes.array,
        value: PropTypes.any,
        label: PropTypes.string
    }

    render() {
        const { label, options, value, onChange } = this.props;
        return options ? (
            <RadioGroup aria-label={label}>
                {
                    options.map((option, index) => (
                        <ControlledLabelLeft
                            key={`${label}-radio-${index}`}
                            checked={value === option}
                            name={option}
                            label={option}
                            onChange={evt => onChange(evt.target.name)}
                            control={<Radio />}
                        />
                    ))
                }
            </RadioGroup>
        ) : null
    }
}
