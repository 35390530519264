import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { isLoading, showModal } from '../../store/actions/app';
import { selectProduct } from '../../store/actions/catalogue';
import { addToWishlist, removeFromWishlist } from '../../store/actions/user';
import { media } from '../../utils/styles';
import { DisplayText } from '../display-text';
import ProductDetails from '../product-details';
import SplitView from '../split-view';

// const ItemCard = styled(Card)`
const ItemCard = styled.div`
    position: relative;
    height: 100%;
    max-width: 15rem;
    min-height: 200px;
    border-radius: 5px;
    padding: 0.5rem;

    .show-on-hover {
        visibility: hidden;
    }
    border: 1px solid #f9f9f9;
    transition: box-shadow 100ms, transform 100ms;
    &:hover {
        box-shadow: rgb(119, 119, 119, 0.5) 0px 1px 13px -4px;
        transform: scale(1);
        border: 1px solid #e4e4e4;

        .bold-on-hover {
            font-weight: 600;
        }

        .show-on-hover {
            visibility: visible;
        }
    }

    .item-code {
        margin: 0;
        color: #5f5f5f;
        font-size: 14px !important;
    } 
`;

// const ItemContent = styled(CardActionArea)`
const ItemContent = styled.div`
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
`;

const FavoriteToggle = styled.div`
    width: 70px;
    height: 70px;
    background: url("${process.env.PUBLIC_URL}/images/heart.png") no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    opacity: 0.3;
  
    ${props => props.isFavorite && css`
        transition-duration: 1s;
        background-position: -1960px 0;
        opacity: 1;
    `}
`;

// const ItemImage = styled(CardMedia)`
const ItemImage = styled.img`
    object-fit: contain;
    object-position: top;
    max-height: 12rem;
    ${media.xs`
        max-height: 8rem;
    `}
    width: 100%;

    ::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

// const ItemDetails = styled(CardContent)`
const ItemDetails = styled.div`
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    justify-content: space-between;
    
    // flex: 1;

    text-align: left;
    cursor: pointer;

    >div {
        /* margin-top: 0.5rem; */
        width: 100%;
    }
`;

class ProductCard extends Component {
    async selected() {
        const { product, onSelect, showLoading, showDetails } = this.props;

        
        showLoading(true);
        await onSelect(product);
        showLoading(false);

        showDetails();
    }

    async favorited(current) {
        const { product, favoriteToggled } = this.props;
        // showLoading(true);
        await favoriteToggled(!current, product);
        // showLoading(false);
    }

    isFavorite() {
        const { product, userFavorites } = this.props;

        return userFavorites.some(item => item === product.id);
    }

    render() {
        const { hasProfile, product, inCart } = this.props;
        const isFav = this.isFavorite();
        return (
            <ItemCard>
                {/* {hasProfile ? <FavoriteToggle className={isFav ? "" : "show-on-hover"} title={`${isFav ? 'added' : 'add'} to wishlist`} isFavorite={isFav} onClick={() => this.favorited(isFav)} /> : null} */}
                <ItemContent>
                    <ItemImage onClick={() => this.selected()} src={product.image} alt={product.name} />
                    <ItemDetails onClick={() => this.selected()}>
                        <DisplayText color="black">
                            {product.name}
                        </DisplayText>
                        <p  className="item-code">
                        
                            {`Item Code: ${product.rewardsCode} `}
                        
                        </p>
                        <SplitView contentHeight spaced justify="flex-start" alignItems="center">
                            <div>
                                <DisplayText color="primary" variant="strong" size="inherit">
                                    {`${product.cost} `}
                                </DisplayText>
                                <DisplayText color="primary" variant="strong" size="inherit">
                                    points
                                </DisplayText>
                            </div>
                            {!!inCart ? (
                                <DisplayText color="light" variant="subdued" size="xsmall" title={`${inCart} in your cart`}>
                                    <DisplayText color="light" size="xsmall" className="bold-on-hover">
                                        {inCart}
                                    </DisplayText>
                                    &nbsp;<FontAwesomeIcon icon={faCartArrowDown} />
                                </DisplayText>
                            ) : null}
                        </SplitView>
                    </ItemDetails>
                </ItemContent>
            </ItemCard>
        )
    }
}

const mapStateToProps = ({ user }) => ({
    hasProfile: !!user.profile,
    userFavorites: user.wishlist
})

const mapDispatchToProps = dispatch => ({
    favoriteToggled: async (fav, product) => dispatch(fav ? addToWishlist(product) : removeFromWishlist(product)),
    onSelect: async product => dispatch(selectProduct(product)),
    showDetails: title => dispatch(showModal(ProductDetails.type, title)),
    showLoading: loading => dispatch(isLoading(loading))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);