import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DataService from '../services/data-service';
import { waitForDialog } from '../store/actions/app';
import { clearCart } from '../store/actions/cart';
import { selectCardForCatalogue } from '../store/actions/catalogue';
import { selectCardForUser, setCards } from '../store/actions/user';
import { media } from '../utils/styles';
import CartWarning from './cart-warning';
import { DisplayText } from './display-text';
import { SimpleLink } from './simple-link';
import { isMobile } from 'react-device-detect';

const SelectionContainer = styled.div`
    width: inherit;
    height: inherit;
    min-height: 200px;
    /* We use a Grid with spacing, this ensures any overflow is hidden */
    padding: 10px;
    overflow: hidden;
`;

const CardList = styled(Grid)`
    justify-content: center;
    margin: 0 -45px !important;
    width: auto;
    /* ${media.md`
        justify-content: flex-start;
    `} */
`;

const GridCard = styled(Grid)`
    height: auto;
    border: 1px solid #888888;
    border-radius: 5px;
    padding: 6px !important;
    
    ${media.xs`
        margin: 10px;
    `}

    ${media.md`
        margin: 20px;
    `}
`;

const CardContainer = styled(SimpleLink)`
    width: 100%;
    height:auto;

    padding: 6px;
`;

const CardImage = styled.div`
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const CardUserDetails = styled.div`
    display: flex;
    flex-direction: column;

    >* {
        text-align: justify;
        ${media.xs`
            text-align: center;
        `}
        width: 100%;
    }
`;

const CardFooter = styled.div`

`;

const DetailLine = styled(DisplayText)`
    line-height: 1rem;
    vertical-align: middle;

    display: flex;
    >* {
        flex: 2;
    }
    >:first-child {
        flex: 1;
    }

    span {
        font-size: 0.8rem !important;
    }
`;

class CardSelection extends Component {
    redirectTo(page, replace) {
        const { location, history } = this.props;
        const newPath = `/${page}`;

        if (location.pathname !== newPath)
            replace ? history.replace(newPath) : history.push(newPath);
    }

    async fetchCards() {
        const { profile, initializeCards } = this.props;

        // Fetch cards
        const cards = !!profile ? await DataService.getUserCards() : await DataService.getAllCards();

        console.log("card det", cards);

        // Update the central state
        initializeCards(cards);

        return cards;
    }

    async cardSelected(card) {
        const code = card.productCode;
        const { catalogueCard, cartCount, cartClear, confirmClear, chooseCard, onSelect } = this.props;
        let confirmation = false;

        // Inform any listeners
        if (onSelect) onSelect(card);

        // if (catalogueCard !== code) {
        if (code) {
            // Items in the cart, and we're switching cards
            // TODO: Show a warning dialog about the cart being cleared
            // TODO: Wait for accepting the warning
            if (cartCount > 0) {
                confirmation = await confirmClear(cartCount);
                // Got confirmation
                if (confirmation) cartClear();
            } else {
                confirmation = true;
            }

            // If not given permission to clear, pretend we're selecting the current card itself
            // Set the chosen card in our central store
            if (confirmation) chooseCard(card);
        } 

        // Move to the catalogue page
        this.redirectTo(`catalogue/${confirmation ? code : catalogueCard}`);
    }

    async componentDidMount() {
        const { cards } = this.props;

        console.log(cards);
        // Fetch cards, if not present on first load
        if (!cards || cards.length <= 0) {
            await this.fetchCards();
        }
    }

    render() {
        const { cards, profile, autoSelect, className } = this.props;
        // In case of a single card, try to auto select (if requested)
        if (autoSelect && cards && cards.length === 1) {
            this.cardSelected(cards[0]);
        }

        return cards && cards.length ? (
            <SelectionContainer className={className}>
                <CardList container>
                    {cards &&
                        cards.map((card, index) => (!profile || card.accountStatus === 'ACTIVE') ? (
                            <GridCard item xs={6} sm={4} md={3} key={`card-${index}-${card.productCode}`}>
                                <CardContainer hasDetails={card.bonusPoints >= 0} onClick={() => this.cardSelected(card)}>
                                    <CardImage>
                                        <img src={card.image} alt={card.productCode} />
                                    </CardImage>
                                    {card.accountDisplay && card.bonusPoints >= 0 ? (
                                        <CardUserDetails>
                                            <DetailLine color="light" variant="strong">
                                                <span>Your Card #&nbsp;</span>
                                                <DisplayText color="#333" variant="strong">
                                                    {card.accountDisplay.substring(isMobile ? 12 : 0)}
                                                </DisplayText>
                                            </DetailLine>
                                            <DetailLine color="light" variant="strong">
                                                <span>Reward Points: &nbsp;</span>
                                                <DisplayText color="primary" variant="strong">
                                                    {card.bonusPoints.toLocaleString('en-IN')}
                                                </DisplayText>
                                            </DetailLine>
                                        </CardUserDetails>
                                    ) : null}
                                </CardContainer>
                                <CardFooter>
                                    <DisplayText size="small">
                                        {`${card.productDescription}`}
                                    </DisplayText>
                                </CardFooter>
                            </GridCard>
                        ) : null)
                    }
                </CardList>
            </SelectionContainer >
        ) : <DisplayText>No active cards linked to your account</DisplayText>
    }
}

const mapStateToProps = ({ user, catalogue, cart }) => ({
    cards: Object.values(user.cards),
    catalogueCard: catalogue.cardCode,
    cartCount: Object.values(cart.products).reduce((acc, curr) => acc + parseInt(curr), 0)
})

const mapDispatchToProps = dispatch => ({
    initializeCards: list => dispatch(setCards(list)),
    chooseCard: card => { dispatch(selectCardForUser(card)); dispatch(selectCardForCatalogue(card.productCode)) },
    cartClear: () => dispatch(clearCart()),
    confirmClear: async (count) => dispatch(waitForDialog(CartWarning.type, '', { count }))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardSelection));