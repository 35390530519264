import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { uid } from 'react-uid';
import styled from 'styled-components';
import OrderDetails from '../components/dialogs/order-details';
import { DisplayText } from '../components/display-text';
import DataService from '../services/data-service';
import { isLoading, setBreadcrumbs, showModal, showSidebar } from '../store/actions/app';

const HistoryContainer = styled.div`
    padding: 30px;
`;

const LinkCell = styled(TableCell)`
    >* {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const HeaderCell = styled(TableCell)`
    color: #333;
    font-weight: 700;
`;

class OrderHistory extends Component {
    state = {
        orderHistory: null
    }

    async componentDidMount() {
        const { showLoading, showBreadcrumbs } = this.props;

        // Show the user profile sidebar
        // toggleSidebar('profile');

        // Do not show any breadcrumbs
        showBreadcrumbs([{ name: 'Home', link: '/' }, { name: `Your Redemption History` }]);

        // Loading while HTTP requests are on
        showLoading(true);

        // Fetch the current user's history
        const orderHistory = await DataService.getUserHistory();
        // Sort by timestamp, descending
        orderHistory.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        this.setState({ orderHistory });

        showLoading(false);
        this.props.toggleSidebar('');
    }

    render() {
        const { showDetails } = this.props;
        const { orderHistory } = this.state;

        
        // const contents = [{
        //     "id": 11982,
        //     "transactionRef": 12982,
        //     "totalPoints": 500,
        //     "status": "CONFIRMED",
        //     "createdAt": "2020-02-10T14:24:04.053Z",
        //     "clientProductCode": "VPC",
        //     "cart": [{
        //         "productId": 439,
        //         "quantity": 1,
        //         "global_code": '7675',
        //         "product_name": 'Amazon EMF',
        //         "final_landed_price": 2900,
        //         "remarks": "DECLINED",
        //         "status": "CONFIRMED"
        //     }]
        // }, {
        //     "id": 11983,
        //     "transactionRef": 12983,
        //     "totalPoints": 2000,
        //     "status": "CONFIRMED",
        //     "createdAt": "2020-02-10T14:26:36.346Z",
        //     "clientProductCode": "VPC",
        //     "cart": [{
        //         "productId": 395,
        //         "quantity": 1,
        //         "global_code": '7675',
        //         "product_name": 'Amazon EMF',
        //         "final_landed_price": 2900,
        //         "remarks": "DECLINED",
        //         "status": "CONFIRMED"
        //     }]
        // }, {
        //     "id": 11985,
        //     "transactionRef": 12985,
        //     "totalPoints": 2000,
        //     "status": "CONFIRMED",
        //     "createdAt": "2020-02-10T14:28:45.790Z",
        //     "clientProductCode": "VPC",
        //     "cart": [{
        //         "productId": 402,
        //         "quantity": 1,
        //         "global_code": '7675',
        //         "product_name": 'Amazon EMF',
        //         "final_landed_price": 2900,
        //         "remarks": "DECLINED",
        //         "status": "CONFIRMED"
        //     }]
        // }, {
        //     "id": 11986,
        //     "transactionRef": 12986,
        //     "totalPoints": 1000,
        //     "status": "CONFIRMED",
        //     "createdAt": "2020-02-10T14:32:22.796Z",
        //     "clientProductCode": "VPC",
        //     "cart": [{
        //         "productId": 439,
        //         "quantity": 1,
        //         "global_code": '7675',
        //         "product_name": 'Amazon EMF',
        //         "final_landed_price": 2900,
        //         "remarks": "DECLINED",
        //         "status": "CONFIRMED"
        //     }, {
        //         "productId": 454,
        //         "quantity": 1,
        //         "global_code": '7675',
        //         "product_name": 'Amazon EMF',
        //         "final_landed_price": 2900,
        //         "remarks": "DECLINED",
        //         "status": "CONFIRMED"
        //     }]
        // }];

        return orderHistory ? (
            <HistoryContainer>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            {!isMobile ? (
                                <HeaderCell>
                                    Sl.No
                            </HeaderCell>
                            ) : null}
                            <HeaderCell>
                                Order Id
                            </HeaderCell>
                            {!isMobile ? (
                                <HeaderCell>
                                    Transaction Id
                            </HeaderCell>
                            ) : null}
                            <HeaderCell>
                                Date
                            </HeaderCell>
                            <HeaderCell>
                                Total Points
                            </HeaderCell>
                            <HeaderCell>
                                Approval Status
                            </HeaderCell>
                        </TableRow>
                    </TableHead> */}
                    {/* <TableBody>
                        {orderHistory.length ? orderHistory.map((order, index) => (
                            <TableRow key={uid(order, index)}>
                                {!isMobile ? (
                                    <TableCell>
                                        {index + 1}
                                    </TableCell>
                                ) : null}
                                <LinkCell>
                                    <DisplayText onClick={() => showDetails(order)} color="primary" size="small">{order.id}</DisplayText>
                                </LinkCell>
                                {!isMobile ? (
                                    <TableCell>
                                        {order.transactionRef}
                                    </TableCell>
                                ) : null}
                                <TableCell>
                                    {new Date(order.createdAt).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {order.totalPoints}
                                </TableCell>
                                <LinkCell>
                                    <DisplayText onClick={() => showDetails(order)} color="primary" size="small">{order.status}</DisplayText>
                                </LinkCell>
                            </TableRow>
                        )) : null}
                    </TableBody> */}

                    <TableHead>
                        <TableRow>
                            {!isMobile ? (
                            <HeaderCell>
                                OrderID
                            </HeaderCell>
                            ) : null}
                            <HeaderCell>
                                TransactionID
                            </HeaderCell>
                            { !isMobile ? (
                            <HeaderCell>
                                Date
                            </HeaderCell>
                            ): null }
                            { !isMobile ? (
                            <HeaderCell>
                                ItemCode
                            </HeaderCell>
                            ) : null }
                            <HeaderCell>
                                ItemName
                            </HeaderCell>
                            <HeaderCell>
                                Qty
                            </HeaderCell>
                            { !isMobile ? (
                            <HeaderCell>
                                PointsPerUnit
                            </HeaderCell>
                            ): null }
                            { !isMobile ? (
                            <HeaderCell>
                                TotalPoints
                            </HeaderCell>
                            ): null }
                            { !isMobile ? (
                            <HeaderCell>
                                ApprovalStatus
                            </HeaderCell>
                            ): null}
                            { !isMobile ? (
                            <HeaderCell>
                                Remarks
                            </HeaderCell>
                            ): null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderHistory.length ? orderHistory.map((order, index) => (
                            order.cart.map((item, i) => (
                                <TableRow key={uid(item, i)}>
                                    {/* {!isMobile ? (
                                        <TableCell>
                                            {i + 1}
                                        </TableCell>
                                    ) : null} */}
                                    <LinkCell>
                                        <DisplayText color="primary" size="small">{order.id}</DisplayText>
                                    </LinkCell>
                                    {!isMobile ? (
                                        <TableCell>
                                            {order.transactionRef}
                                        </TableCell>
                                    ) : null}
                                    {!isMobile ? (
                                    <TableCell>
                                        {new Date(order.createdAt).toLocaleDateString()}
                                    </TableCell>
                                    ): null }
                                    {!isMobile ? (
                                        <TableCell>
                                            {item.global_code}
                                        </TableCell>
                                    ) : null}
                                    <TableCell>
                                        {item.product_name}
                                    </TableCell>
                                    <TableCell>
                                        {item.quantity}
                                    </TableCell>
                                    {!isMobile ? (
                                    <TableCell>
                                        {item.final_landed_price}
                                    </TableCell>
                                    ): null}
                                    {!isMobile ? (
                                    <TableCell>
                                        {order.totalPoints}
                                    </TableCell>
                                    ): null}
                                    {!isMobile ? (
                                    <TableCell>
                                        {item.status}
                                    </TableCell>
                                    ): null}
                                    {!isMobile ? (
                                    <TableCell>
                                        {item.remarks}
                                    </TableCell>
                                    ): null}
                                    <LinkCell>
                                        <DisplayText onClick={() => showDetails({id:order.id, createdAt: order.createdAt, clientProductCode: order.clientProductCode, transactionRef: order.transactionRef, cart: item})} color="primary" size="small">View Details</DisplayText>
                                    </LinkCell>
                                </TableRow>
                            ))


                            // <TableRow key={uid(order, index)}>
                            //     {!isMobile ? (
                            //         <TableCell>
                            //             {index + 1}
                            //         </TableCell>
                            //     ) : null}
                            //     <LinkCell>
                            //         <DisplayText onClick={() => showDetails(order)} color="primary" size="small">{order.id}</DisplayText>
                            //     </LinkCell>
                            //     {!isMobile ? (
                            //         <TableCell>
                            //             {order.transactionRef}
                            //         </TableCell>
                            //     ) : null}
                            //     <TableCell>
                            //         {new Date(order.createdAt).toLocaleDateString()}
                            //     </TableCell>
                            //     <TableCell>
                            //         {order.totalPoints}
                            //     </TableCell>
                            //     <LinkCell>
                            //         <DisplayText onClick={() => showDetails(order)} color="primary" size="small">{order.status}</DisplayText>
                            //     </LinkCell>
                            // </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
                {!orderHistory.length ? (
                    <DisplayText color="light" size="small">No orders to show</DisplayText>
                ) : null}
            </HistoryContainer>
        ) : null
    }}

const mapStateToProps = ({ user }) => ({
    profile: user.profile
})

const mapDispatchToProps = dispatch => ({
    showLoading: show => dispatch(isLoading(show)),
    showBreadcrumbs: (paths) => dispatch(setBreadcrumbs(paths)),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props)),
    showDetails: data => dispatch(showModal(OrderDetails.type, <DisplayText size="medium" variant="strong">Order #{data.id}</DisplayText>, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);