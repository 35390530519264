import { user as actionTypes } from '../actions/actionTypes';

const initialState = {
    profile: null,
    selectedCard: null,
    wishlist: [],
    cards: {},
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.LOGIN:
            return {
                ...state,
                profile: payload
            }

        case actionTypes.LOGOUT:
            return {
                ...state,
                profile: null
            }

        case actionTypes.SET_CARDS:
            return {
                ...state,
                cards: payload
            }

        case actionTypes.SELECT_CARD:
            return {
                ...state,
                selectedCard: payload
            }

        case actionTypes.SET_WISHES:
            return {
                ...state,
                wishlist: [...payload]
            }

        case actionTypes.ADD_WISH:
            return {
                ...state,
                wishlist: [...state.wishlist, payload]
            }

        case actionTypes.REMOVE_WISH:
            return {
                ...state,
                wishlist: [...(state.wishlist.filter(wish => wish !== payload))]
            }
        default:
            return state
    }
}
