import { SnackbarContent } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearCart } from '../../store/actions/cart';
import { DisplayText } from '../display-text';
import OrderPreview from './order-preview';
import {media} from '../../utils/styles';

const ConfirmationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StatusMessage = styled.div`
    ${media.xs`
        width: 95%;
        max-width: 425px;
    `}    
`;

const OrderStatus = styled.div`

`;

const PreviewContainer = styled(OrderPreview)`
    margin-top: 2rem;
`;

const Success = styled(SnackbarContent)`
    box-shadow: 0px 1px 2px #ccc;
    background-color: #cce2cb;
    border-color: #00aa00;
    color: #004400;
`;

class OrderConfirmation extends Component {
    async componentDidMount() {
        const { cartClear } = this.props;

        cartClear();
    }

    render() {
        const { products: purchasedProduct, selectedCard } = this.props;

        return (
            <ConfirmationContainer>
                <>
                    <OrderStatus>
                        <h2>Congratulations!</h2>
                        Your order is placed against HSBC {selectedCard.productDescription} ending with card number <DisplayText color="primary" variant="strong">{selectedCard.accountDisplay}</DisplayText>
                        <br />{/* Order placed against "{selectedCard.productDescription}" account number <DisplayText color="primary" variant="strong">{selectedCard.accountDisplay}</DisplayText> */}
                    </OrderStatus>
                    {/* <Success message="Your request has been received and will be processed within 2 working days. Once approved, your gift(s) should reach you within 15 working days via courier, whereas gift vouchers should reach you within 7 working days via email / SMS on the below mentioned details. Handling charges of INR 50  /- per redemption will be levied for HSBC Classic, Gold, Smart Value and Platinum and Advance Platinum Credit Cards, except on donation requests." /> */}
                     <StatusMessage >
                     <Success message="The request shall be processed within 2 working days. Once approved, your gift(s) should reach you within 15 working days via courier, whereas e-gift voucher(s) should reach you within 7 working days via email/SMS on the below mentioned details. Handling charges of INR 50/- per redemption will be levied for HSBC Classic, Gold, Smart Value and Platinum and Advance Platinum Credit Cards, except on donation requests." />
                    </StatusMessage>

                    <DisplayText color="secondary" variant="strong">Note: Your reward points will be refreshed after your redemption request has been processed.
                        </DisplayText>
                </>
                <PreviewContainer lastCart={purchasedProduct} isConfirm={true}/>
                
            </ConfirmationContainer >
        )
    }
}

const mapStateToProps = ({ cart }) => ({
    products: Object.entries(cart.backup).reduce((acc, [id, value]) => {
        if (id in cart.details) {
            let prod = cart.details[id];
            prod.quantity = value;
            prod.total = prod.quantity * prod.cost;
            acc.push(prod);
        }
        return acc;
    }, [])
})

const mapDispatchToProps = dispatch => ({
    cartClear: () => dispatch(clearCart())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);