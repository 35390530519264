import { Button, SnackbarContent } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import OrderCart from '../components/checkout/order-cart';
import OrderConfirmation from '../components/checkout/order-confirmation';
import OrderPreview from '../components/checkout/order-preview';
import OrderShipping from '../components/checkout/order-shipping';
import { DisplayText } from '../components/display-text';
import Timeline from '../components/timeline';
import DataService from '../services/data-service';
import { isLoading, setBreadcrumbs, showSidebar } from '../store/actions/app';
import { media } from '../utils/styles';

const CHECKOUT_STEPS = [
    {
        name: 'View Cart',
        component: OrderCart
    },
    {
        name: 'Shipping',
        component: OrderShipping
    },
    {
        name: 'Preview',
        doCheckout: true,
        component: OrderPreview
    },
    {
        name: 'Order Summary',
        component: OrderConfirmation
    }
];

const CheckoutContainer = styled.div`
    padding: 10px 20px;
    ${media.xs`
        margin-top: 1rem;
    `}
`;

const CheckoutTimeline = styled(Timeline)`
    padding: 20px;
`;

const CheckoutStep = styled.div`
    flex: 1;
    text-align: left;

    margin: -8px;
`;

const CheckoutActions = styled.div`
    width: 100%;

    text-align: right;
    margin-top: 2rem;
`;

const Warning = styled(SnackbarContent)`
    margin-top: 2rem;
    
    box-shadow: 0px 1px 2px #ccc;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
`;

const ProceedButton = styled(({ faded, ...rest }) => <Button {...rest} />)`
    width: fit-content;
    background-color: #db0011;
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 6px;

    :hover {
        background-color: #db0011;
    }
`;

class CheckoutCart extends Component {
    state = {
        activeStep: 0,
        warning: ''
    }

    previousStep() {
        const activeStep = this.state.activeStep - 1;

        if (activeStep < 0) {
            const { history } = this.props;
            history.goBack();
        } else {
            this.setState({ activeStep });
        }
    }

    async nextStep() {
        // Tryto checkout, if applicable, then move to the next step
        const doneOrSkip = await this.tryCheckout();

        if (doneOrSkip) {
            const activeStep = this.state.activeStep + 1;

            if (activeStep >= CHECKOUT_STEPS.length) {
                const { history } = this.props;
                history.replace('/');
            } else {
                this.setState({ activeStep });
                // Scroll to the top when the checkout step changes
                window.scrollTo(0, 0);
            }
        }
    }

    async tryCheckout() {
        const { activeStep } = this.state;

        // This is a checkout step
        if (CHECKOUT_STEPS[activeStep].doCheckout) {
            const { showLoading, selectedCard, products } = this.props;

            // Start loading the confirmation
            showLoading(true);

            let completed = false;
            try {
                // Do the processing
                completed = await DataService.checkoutForCard(selectedCard, products);
            } catch (err) {
                console.error(err);
            }

            // Done loading
            showLoading(false);

            // Set any error, if it occurred
            this.setState({ warning: completed ? null : 'Sorry! Your redemption could not be completed due to a temporary network issue. Please try again after sometime. If the problem persists, please call HSBC PhoneBanking.' });

            return completed;
        }

        return true;
    }

    canUserProceed() {
        const { warning } = this.state;

        // Must have no warnings, or should be on the last step
        return (!warning) || (this.state.activeStep >= (CHECKOUT_STEPS.length - 1));
    }

    canComponentProceed(allow, err) {
        if (err) this.setState({ warning: err });
        else if (!allow) this.setState({ warning: 'no-text' })
        else if (this.state.warning) this.setState({ warning: '' })
    }

    componentDidMount() {
        const { showBreadcrumbs, toggleSidebar, selectedCard } = this.props;
        showBreadcrumbs([
            { name: 'Cards', link: '/' },
            {
                name: 'Catalogue',
                link: `/catalogue/${selectedCard.productCode}`
            },
            { name: 'Cart' }
        ]);
        toggleSidebar('');
    }

    render() {
        const { products, selectedCard } = this.props;
        const { activeStep, warning } = this.state;
        const ActiveComponent = CHECKOUT_STEPS[activeStep].component;
        const canProceed = this.canUserProceed();

        return (
            <CheckoutContainer>
                <CheckoutTimeline active={activeStep} numbers={true} steps={Object.values(CHECKOUT_STEPS).map(step => step.name)} />
                {warning && warning !== 'no-text' ? (
                    <Warning message={warning} />
                ) : null}
                <CheckoutStep>
                    <ActiveComponent
                        products={products}
                        canProceed={canProceed}
                        selectedCard={selectedCard}
                        verifyStep={(allow, err) => this.canComponentProceed(allow, err)}
                    />
                </CheckoutStep>
                <CheckoutActions>
                    {activeStep < (CHECKOUT_STEPS.length - 1) ? (
                        <Button onClick={() => this.previousStep()}>
                            <DisplayText color="secondary">Back</DisplayText>
                        </Button>
                    ) : null}
                    <ProceedButton disabled={!canProceed} onClick={() => this.nextStep()}>
                    <DisplayText color="white">{activeStep < (CHECKOUT_STEPS.length - 1) ? ( (activeStep == 2) ? 'Redeem Now' :'Proceed' ) : 'Complete'}</DisplayText>
                    </ProceedButton>
                </CheckoutActions>
            </CheckoutContainer>
        )
    }
}

const mapStateToProps = ({ user, cart, catalogue }) => ({
    // selectedCard: user.cards ? user.cards[catalogue.cardCode] : null,
    selectedCard: user.cards ? user.selectedCard : null,
    products: Object.entries(cart.products).reduce((acc, [id, qty]) => {
        if (id in cart.details) {
            const product = { ...cart.details[id] };
            product.quantity = qty;
            product.total = qty * product.cost;
            acc.push(product);
        }
        return acc;
    }, [])
})

const mapDispatchToProps = dispatch => ({
    showLoading: show => dispatch(isLoading(show)),
    showBreadcrumbs: (paths) => dispatch(setBreadcrumbs(paths)),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutCart));