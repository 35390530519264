import { Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledSlider = styled(Slider)`
    color: #db0011;
    height: 1rem;
    padding-top: 2.5rem;
    
    .MuiSlider {
        &-thumb {
            height: 1.3rem;
            width: 1.3rem;

            margin-top: -0.5rem;
            margin-left: -0.5rem;

            background-color: #fff;
            border: 1px solid #ccc;
            box-shadow: #ccc 0px 2px 2px;
            &:focus,&:hover,&:active {
                box-shadow: #ccc 0px 2px 3px 1px;
            }
        }
        .active {}
        &-valueLabel {
            left: -50%;
            top: -1.5rem;
            & * {
                background: transparent;
                color: #000;
            }
        }
        &-track {
            height: 0.5rem;
        }
        &-rail {
            color: #d8d8d8;
            opacity: 1;
            height: 0.5rem;
        }
    }
`;

export default class RangeSlider extends Component {
    static propTypes = {
        min: PropTypes.number,
        max: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired
    }

    static defaultProps = {
        min: 0,
        max: 100
    }

    state = {
        local: null
    }

    render() {
        const { onChange, value, ...rest } = this.props;
        const { local } = this.state;

        let { min, max } = rest;
        // Don't allow overlapping boundaries
        if (min === max) {
            min = ((min - 50) < 0) ? 0 : (min - 50);
            max = min + 100;
        }

        return (
            <StyledSlider
                min={min} max={max}
                valueLabelDisplay='on'
                getAriaLabel={index => (index === 0 ? 'Minimum' : 'Maximum')}
                value={local || value || [min, max]}
                onChange={(evt, newValue) => {
                    if (newValue[0] < newValue[1]) this.setState({ local: newValue });
                    else evt.preventDefault();
                }}
                onChangeCommitted={(evt, newValue) => {
                    if (newValue[0] < newValue[1]) onChange(newValue);
                    else evt.preventDefault();

                    this.setState({ local: null });
                }}
            />
        )
    }
}
