import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Catalogue from '../pages/catalogue';
import CheckoutCart from '../pages/checkout-cart';
import Home from '../pages/home';
import NotFound from '../pages/not-found';
import { media } from '../utils/styles';
import OrderHistory from '../pages/order-history';
import FAQ from '../pages/faq';
import TnC from '../pages/tnc';

const PageContainer = styled(({ children, ...props }) => (
    <Grid container {...props}>
        <Grid item xs={12}>
            {children}
        </Grid>
    </Grid>
))`
    padding: 1rem;

    ${media.xs`
        padding: 0;
    `}
`;

export default class PageContent extends Component {
    render() {
        return (
            <PageContainer>
                <Switch>
                    <Route path="/catalogue/:code" component={Catalogue} />
                    <Route path="/checkout" component={CheckoutCart} />
                    <Route path="/order-history" component={OrderHistory} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/tnc" component={TnC} />
                    <Route path="/" component={Home} />
                    <Route path="" component={Home} />
                    {/* Default to a HTTP 404 page */}
                    <Route component={NotFound} />
                </Switch>
            </PageContainer>
        )
    }
}
