import { app as actionTypes } from '../actions/actionTypes';

const inititalState = {
    loading: false,
    logonredirection: false,
    toast: null,
    header: null,
    modal: null,
    sidebar: {
        type: '',
        props: {}
    },
    modalResolve: null,
    breadcrumbs: null
}

export default (state = inititalState, { type, payload }) => {
    switch (type) {
        case actionTypes.SHOW_TOAST:
            return {
                ...state,
                toast: payload || true
            }
        case actionTypes.HIDE_TOAST:
            return {
                ...state,
                toast: null
            }
        case actionTypes.SHOW_HEADER:
            return {
                ...state,
                header: payload || true
            }
        case actionTypes.HIDE_HEADER:
            return {
                ...state,
                header: null
            }
        case actionTypes.SHOW_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    type: payload.type,
                    props: payload.props ? { ...payload.props } : {}
                }
            }
        case actionTypes.HIDE_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    type: '',
                    props: {}
                }
            }
        case actionTypes.SHOW_MODAL:
            return {
                ...state,
                modal: payload || true
            }
        case actionTypes.HIDE_MODAL:
            return {
                ...state,
                modal: null
            }
        case actionTypes.LOADING:
            return {
                ...state,
                loading: payload
            }
        case actionTypes.LOGON_REDIRECTION:
            return {
                ...state,
                logonredirection: payload
            }
        case actionTypes.SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: [...payload]
            }
        default:
    }

    return state;
}