import DataService from '../../services/data-service';
import { user as actionTypes } from './actionTypes';
import { setCart } from './cart';

const userLogin = profile => ({
    type: actionTypes.LOGIN,
    payload: profile
});

const userLogout = () => ({
    type: actionTypes.LOGOUT
});

export const login = profile => async dispatch => {
    // Start a session on our backend
    await DataService.startSession(profile.contactDetails.customerEmail.emailAddress);

    const cardList = await DataService.getUserCards();
    if (cardList && cardList.length) {
        const populatedCards = await Promise.all(cardList.map(async card => {
            const details = await DataService.getCardDetails(card);
            return { ...card, ...details }
        }))

        dispatch(setCards(populatedCards));
    }

    // TODO: Fetch the user cart
    // const cart = await DataService.getUserCart();
    // dispatch(setCart(cart.reduce((acc, curr) => {
    //     acc[curr.id] = curr.quantity;
    // }, {})));

    // Fetch the wishlist items
    // const wishlist = await DataService.getUserWishlist();
    // dispatch(setWishlist(wishlist));

    dispatch(userLogin(profile));
}

export const logout = () => dispatch => {
    dispatch(userLogout());
    // Empty the local cart
    dispatch(setCart({}));
    // Empty the available cards
    dispatch(setCards([]));
    // Clear any local storage
    DataService.logout();
}

export const setCards = cardList => ({
    type: actionTypes.SET_CARDS,
    // payload: cardList.reduce((acc, curr) => {
    //     acc[curr.productCode] = curr;
    //     // console.log("card det", acc);
    //     return acc;
    // }, {})
    payload: cardList.map((acc, index) => {
        // acc[curr.productCode] = curr;
        // console.log("card det", acc);
        return acc;
    })
})

export const selectCardForUser = card => ({
    type: actionTypes.SELECT_CARD,
    payload: card
})

export const setWishlist = products => ({
    type: actionTypes.SET_WISHES,
    payload: products
})

export const wishItem = product => ({
    type: actionTypes.ADD_WISH,
    payload: product.id
})

export const unwishItem = product => ({
    type: actionTypes.REMOVE_WISH,
    payload: product.id
})

export const addToWishlist = product => async dispatch => {
    await DataService.updateWishlist(product.id, false);
    dispatch(wishItem(product));
}

export const removeFromWishlist = product => async dispatch => {
    await DataService.updateWishlist(product.id, true);
    dispatch(unwishItem(product));
}