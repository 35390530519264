import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { DisplayText } from './display-text';

const TimelineContainer = styled.div`
    width: 100%;
    padding-top: 1.5rem;
`;

const Line = styled.div`
    margin-left: 1.5rem;
    width: calc(100% - 4rem);
    
    height: 1px;
    background-color: #ccc;
`;

const Steps = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const Step = styled.div`
    cursor: default;
    
    margin-top: -1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StepMarker = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: darkgray;
    background-color: rgb(245, 245, 245);
    
    ${props => props.isComplete && css`
        color: white;
        background-color: dimgray;
    `};

    ${props => props.isActive && css`
        color: white;
        background-color: #db0011;
    `};
`;

const StepLabel = styled(DisplayText)`
    margin-top: 0.5rem;
`;

export default class Timeline extends Component {
    static propTypes = {
        steps: PropTypes.array,
        numbers: PropTypes.bool
    }

    render() {
        const { steps, numbers, active } = this.props;
        return (
            <TimelineContainer>
                <Line />
                <Steps>
                    {
                        steps.map((step, index) => (
                            <Step key={`checkout-step-${index}`}>
                                <StepMarker isComplete={index < active} isActive={index === active}>
                                    {numbers && <DisplayText variant="strong" color="inherit" size="medium">{index + 1}</DisplayText>}
                                </StepMarker>
                                <StepLabel size="small" color={index === active ? '' : 'light'}>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Steps>
            </TimelineContainer>
        )
    }
}
