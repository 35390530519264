import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from '../../utils/styles';
import { DisplayText } from '../display-text';
import SplitView from '../split-view';
import ProfileError from './profile-error';

const PreviewContainer = styled.div`
    width: 100%;
`;

const Preview = styled.div`
    flex: 1;
    margin-top: 40px;
`;
const CartFooter = styled.div`
    text-align: left;
    margin-top: 5rem;
    border-top: 1px solid #999;
`;
const PreviewTitle = styled(DisplayText)`
    font-size: 1.5rem;
    font-weight: 700;
`;

const PreviewSep = styled.div`
    width: 100%;
    height: 1px;

    margin: 0.5rem 0;
    background-color: #ccc;
`;

const SplitPreview = styled(SplitView)`
    >:first-child {
        margin-left: 0;
        margin-right: 2rem;
        
        flex: 2;
        ${media.xs`
            flex: 1;
        `}
    }

    >:last-child {
        margin-left: 0;
        margin-right: 0;

        flex: 1;
    }

    ${media.xs`
        width: 80%;
        >* {
            margin-bottom: 1rem;
        }
    `}
`;

const PreviewHeader = styled(({ title, ...props }) => (
    <div>
        <PreviewTitle>
            {title}
        </PreviewTitle>
        <PreviewSep />
    </div>
))`

`;

const ShippingLine = styled.div`
    margin-top: 0.5rem;
`;

const TableContainer = styled.div`
    ${media.xs`
        width: 320px; 
        max-width: 340px;
        overflow-x: scroll;
    `} 

    ${media.md`
        min-width: 411px;
        max-width: 480px;
        width: 80%;
        overflow-x: scroll;
    `} 

    ${media.lg`
        width: 100%;
        min-width: 1024px;
        max-width: 1400px;
        overflow-x: auto;
    `}
`;

class OrderPreview extends Component {
    columnConfig = [
        // {
        //     title: '#',
        //     renderValue: (row, index) => index
        // },
        {
            title: 'Item Code',
            renderValue: (row, index) => row.rewardsCode
        },
        {
            title: 'Item Name',
            renderValue: (row, index) => row.name
        },
        {
            title: 'Quantity',
            renderValue: (row, index) => row.quantity
        },
        {
            title: 'Points',
            renderValue: (row, index) => row.cost
        },
        {
            title: 'Total',
            renderValue: (row, index) => row.cost * row.quantity
        },
    ]

    render() {
        const { products, lastCart, className, profile, isConfirm } = this.props
        const { contactDetails, personalInformation } = profile;

        // If we're running in the history mode prefer the previous cart
        const displayProducts = !!lastCart ? lastCart : products;

        return (
            <PreviewContainer className={className}>
                
                    <Preview>
                        <PreviewHeader title="Order Preview" />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.columnConfig.map((conf, idx) => (
                                            <TableCell key={`summ-head-${idx}`}>
                                                <DisplayText color="light" variant="strong">
                                                    {conf.title}
                                                </DisplayText>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayProducts.map((product, index) => (
                                        <TableRow key={`summary-prod-${index}`}>
                                            {this.columnConfig.map((conf, idx) => (
                                                <TableCell key={`summ-row-${index}-col-${idx}`}>
                                                    <DisplayText>
                                                        {conf.renderValue(product, index)}
                                                    </DisplayText>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={2} />
                                        <TableCell colSpan={2}>
                                            <DisplayText color="light" variant="strong">
                                                Total
                                            </DisplayText>
                                        </TableCell>
                                        <TableCell>
                                            <DisplayText>
                                                {displayProducts.reduce((a, c) => a + (c.cost * c.quantity), 0)}
                                            </DisplayText>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Preview>
                    
                

                <Preview>
                        <PreviewHeader title="Shipping Address" />
                        {/* First Name + Last Name */}
                        <ShippingLine>
                            {personalInformation ? <DisplayText variant="strong" size="medium">{`${personalInformation.customerName.firstName} ${personalInformation.customerName.lastName}`}</DisplayText> : <ProfileError />}
                        </ShippingLine>
                        <DisplayText size="small" color="light">
                        Items will be delivered to your credit card statement mailing address updated in the Bank records.
                            <br />
                            Electronic gift vouchers will be delivered only via email/SMS on the registered email ID and mobile number below 
                        </DisplayText>
                        {contactDetails && contactDetails.mobilePhoneNumber ? <ShippingLine>{contactDetails.mobilePhoneNumber.fullNumber}</ShippingLine> : <ProfileError />}
                        {contactDetails && contactDetails.customerEmail ? <ShippingLine>{contactDetails.customerEmail.emailAddress}</ShippingLine> : <ProfileError />}
                        
                    </Preview>
                <CartFooter>

                    <DisplayText size='small' variant='strong' color='black'>
                        Disclaimer:
                    </DisplayText>
                    {
                        isConfirm ? 
                        <DisplayText color="secondary" size="small">
                            In case your credit card statement mailing address needs to be updated, please reach out to your nearest HSBC India Branch or call HSBC PhoneBanking prior to placing the request to avoid incorrect delivery
                        </DisplayText>
                        :
                        <DisplayText color="secondary" size="small">
                            The redemption of any product in this catalogue is subject to The Hongkong and Shanghai Banking Corporation Limited prevailing Terms and Conditions governing the Bank's Rewards Programme. In particular, please note that the Bank makes no warranty whatsoever as to the quality of fitness for any particular purpose of any product shown in this catalogue. Any dispute you may have concerning any product shall be settled with the relevant service establishment and/or supplier which supplied the product. The Bank will bear no responsibility for resolving such disputes or for the dispute itself.
                        </DisplayText>
                    }
                    <br />
                    <br />
                    {!isConfirm ? 
                        (<p>
                            <DisplayText size='small' variant='strong' color='black'>
                                Please note:
                            </DisplayText>
                            <ul>
                                <li>
                                    <DisplayText size='small' variant='light' color='subdued'>
                                        No delivery will be made to P.O. Box addresses or to addresses outside India.
                                    </DisplayText>
                                </li>
                                <li>
                                    <DisplayText size='small' variant='light' color='subdued'>
                                        Kindly select products from the applicable credit card category under the
                                    rewards catalogue to ensure successful redemption.
                                </DisplayText>
                                </li>
                                <li>
                                    <DisplayText size='small' variant='light' color='subdued'>
                                        Products requested against redemption of points will be delivered to your
                                        registered mailing address as per bank records. Should you wish to update
                                        your mailing address then please call PhoneBanking OR visit your nearest
                                        HSBC branch for assistance, prior to placing your redemption request.
                                </DisplayText>
                                </li>
                            </ul>
                        </p>)
                        : ''
                    }
                </CartFooter>
            </PreviewContainer>
        )
    }
}

const mapStateToProps = ({ user }) => ({
    profile: user.profile
})

export default connect(mapStateToProps)(OrderPreview);