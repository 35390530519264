import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { hideToast, showToast } from '../../store/actions/app';
import { DisplayText } from '../display-text';
import { applyFilters } from '../filters/config';
import ProductCard from './product-card';
import {media} from  '../../utils/styles';

const ListContainer = styled.div`
    width: 100%;
    /* We use a Grid with spacing, this ensures any overflow is hidden */
    padding: 5px;
    overflow: hidden visible;

    min-height: 50vh;
`;

const ListContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    >* {
        ${media.lg`
            padding: 18px 0;
            margin: 8px 0;
        `}

        ${media.md`
            padding: 8px;
            margin: 8px 0;
        `}

        ${media.xs`
            padding: 8;
            margin: 8px 0;
        `}
    }
`;

const ListItem = styled(props => <Grid item xs={6} sm={4} md={3} {...props} />)``;

const EmptyListText = styled(props => <DisplayText color="light" variant="subdued" {...props} />)`
    width: 100%;

    padding: 5rem 0;
`;

class ProductList extends Component {
    state = {
        filteredProducts: []
    }

    async filterProducts() {
        const { products, filters, showFiltering } = this.props;

        // Loading
        showFiltering(true);

        const filtered = await applyFilters(products, filters);
        this.setState({ filteredProducts: filtered });

        // Complete
        showFiltering(false);
    }

    componentDidUpdate({ filters: oldFilters, products: oldProducts }) {
        const { filters: newFilters, products: newProducts } = this.props;
        // Either the filters can change,
        // Or the length of the products is different
        // Or some elements in the new list are different
        if (oldFilters !== newFilters
            || newProducts.length !== oldProducts.length
            || newProducts.some(p => oldProducts.indexOf(p) < 0)) {
            // Re-filter
            this.filterProducts();
        }
    }

    componentDidMount() {
        // Filter on each mount
        this.filterProducts();
    }

    render() {
        const { cart, className } = this.props;
        const { filteredProducts } = this.state;
        const hasProducts = (filteredProducts && filteredProducts.length);

        return (
            <ListContainer className={className}>
                <ListContent container>
                    {
                        hasProducts ?
                            filteredProducts.map((product, index) => (
                                <ListItem key={`product-listing-${index}`}>
                                    <ProductCard product={product} inCart={cart[product.id]} />
                                </ListItem>
                            ))
                            :
                            <EmptyListText>No Products Found</EmptyListText>
                    }
                </ListContent>
            </ListContainer >
        )
    }
}

const mapStateToProps = ({ catalogue, user, cart }) => ({
    filters: catalogue.filters,
    products: Object.values(catalogue.products).sort((a, b) => b.cost - a.cost),
    selectedCard: user.cards ? user.cards[catalogue.cardCode] : null,
    cart: cart.products
})

const mapDispatchToProps = dispatch => ({
    showFiltering: loading => dispatch(loading ? showToast({ duration: null, message: 'Filtering...' }) : hideToast())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);