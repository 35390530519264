import { combineReducers } from 'redux';
import app from './app';
import cart from './cart';
import catalogue from './catalogue';
import user from './user';

const rootReducer = combineReducers({
    app,
    cart,
    catalogue,
    user
});

export default rootReducer;