import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

export const Loader = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;

    ::before {
        content: " ";
        display: block;
        width: 3rem;
        height: 3rem;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid #000;
        border-color: #000 transparent #000 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    z-index: 9999;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.6);

    ${props => !props.isLogonRedirection && css`
        display: none;
    `};
`;

const LogonRedirectionScreen = ({ logonredirection, appLogonRedirection }) => (
    <LoadingContainer isLogonRedirection={logonredirection !== undefined ? logonredirection : appLogonRedirection}>
        <Loader>
            You are now being redirected back to HSBC India Website for authentication.
        </Loader>
    </LoadingContainer>
)

const mapStateToProps = ({ app }) => ({
    appLogonRedirection: app.logonredirection
})

export default connect(mapStateToProps)(LogonRedirectionScreen);