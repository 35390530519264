import React from 'react';
import Sidebar from './index';
import { withRouter } from 'react-router-dom';
import { SimpleLink } from '../simple-link';
import { DisplayText } from '../display-text';

function UserBar({ localtion, history, ...rest }) {
    return (
        <Sidebar current="profile" {...rest}>
            <div>
                {/* <SimpleLink onClick={() => history.push('/profile')}>
                    <DisplayText size="small" color="#333">Profile</DisplayText>
                </SimpleLink> */}
                {/* <SimpleLink onClick={() => history.push('/wishlist')}>
                    <DisplayText size="small" color="#333">Wishlist</DisplayText>
                </SimpleLink> */}
                {/* <SimpleLink onClick={() => history.push('/order-history')}>
                    <DisplayText size="small" color="#333">Order History</DisplayText>
                </SimpleLink> */}
            </div>
        </Sidebar>
    )
}

export default withRouter(UserBar);
