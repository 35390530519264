import React, { Component } from 'react';
import { WarningButton, WarningContent } from './dialogs/warning';
import { DisplayText } from './display-text';

export default class SessionWarning extends Component {
    static propTypes = {

    }

    static type = 'SESSION_WARNING'
    static dialogProps = {
        title: (
            <DisplayText color="primary">Warning</DisplayText>
        ),
        actions: ({ onClose }) => (
            <>
                <WarningButton text='Proceed' color='primary' onClick={() => onClose(true)} />
            </>
        )
    }

    render() {
        return (
            <WarningContent>
                <DisplayText>
                    Session expired, and your details have bee cleared. Please login again to view a personalized list of cards.
                </DisplayText>
            </WarningContent>
        )
    }
}
